var SelectAddtionalFibreProductSeries = function($container, search, next, name) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'product_series'
    this.title = 'Select product series'
    this.modelName = name
}

SelectAddtionalFibreProductSeries.prototype = Object.create(CableBuilderStep.prototype)

SelectAddtionalFibreProductSeries.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'fibre_mode',
            value: customCableData.connector_one.fibre_mode
        }    
    ]
}
