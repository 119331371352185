var SelectFibreMode = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'fibre_mode'
    this.title = 'Select mode'
    this.modelName = 'connector_one'
}

SelectFibreMode.prototype = Object.create(CableBuilderStep.prototype)

SelectFibreMode.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'product_series',
            value: customCableData[this.modelName].product_series
        },
        {
            field: 'cable_function',
            value: customCableData[this.modelName].cable_function
        }
    ]
}
