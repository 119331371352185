EventBus.subscribe('scroll-to-target', function (params) {
    if (!params.hasOwnProperty('target') || params.target.length === 0) {
        return false;
    }

    var position = params.target.offset().top;
    
    //Option for including header height
    if (params.hasOwnProperty('offset')) {
        position -= params.offset;
    }

    if (params.hasOwnProperty('bottom')) {
        position += params.target.outerHeight();
        position -= jQuery(window).height();
    }

    jQuery('html,body').stop().animate({
        scrollTop: position,
    }, params.hasOwnProperty('speed') ? params.speed : 'slow', params.hasOwnProperty('callback') ? params.callback : null);
    
});