var GoogleApiModule = function () {
    
    var self = this;
    
    /**
     * Whether Google API has been loaded
     * @type {boolean}
     */
    self.isLoaded = false;
    
    /**
     * Whether Google API is being loaded
     * @type {boolean}
     */
    self.isLoading = false;
    
    /**
     * Callbacks to execute once the API is loaded
     * @type {boolean}
     */
    self.callbacks = [];
    
    /**
     * Load Google API
     * @param callback
     */
    self.loadApi = function (callback) {
        
        //Execute callback if Google API has already been loaded
        if (self.isLoaded) {
            callback();
            return;
        }
        
        //Add callback to callbacks list
        self.callbacks.push(callback);
        
        //Cancel if API is being loaded
        if (self.isLoading) {
            return;
        }
        
        //Set that the API is being loaded
        self.isLoading = true;
        
        //Load the API
        jQuery.getScript('https://www.google.com/jsapi', function () {
            
            //Set that the API has been loaded
            self.isLoaded = true;
            self.isLoading = false;
            
            //Execute callbacks now that the API is loaded
            jQuery.each(self.callbacks, function (i, cb) {
                cb();
            });
            
        });
        
    };
    
    /**
     * Bind events
     */
    self.bindEvents = function () {
        EventBus.subscribe('load-google-api', self.loadApi);
    };

    self.bindEvents();
    
};

EventBus.subscribe('init-modules', function () {
    new GoogleApiModule();
});