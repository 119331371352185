var SelectPowerVoltageMax = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'voltage_max'
    this.title = 'Select max voltage'
    this.modelName = 'connector_one'
}

SelectPowerVoltageMax.prototype = Object.create(CableBuilderStep.prototype)

SelectPowerVoltageMax.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'cable_function',
            value: customCableData[this.modelName].cable_function
        },
        {
            field: 'contact_type',
            value: customCableData[this.modelName].contact_type
        },
        {
            field: 'number_of_contacts',
            value: customCableData[this.modelName].number_of_contacts
        },
        {
            field: 'current_max',
            value: customCableData[this.modelName].current_max
        }
    ]
}
