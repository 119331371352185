var CableBuilderInputStep = function ($container, next) {
    this.$container = $container
    this.next = next
    this.$input = null
}

CableBuilderInputStep.prototype.run = function() {
    this.saveAndDisableInput()
    this.next.setup()
}

CableBuilderInputStep.prototype.setup = function() {
    this.renderInput()
}

CableBuilderInputStep.prototype.renderInput = function() {
    this.$input = $('<input/>', {
        name: this.optionName,
        type: 'number',
        class: 'connector-type form-control',
        placeholder: this.title
    })

    $button = $('<button/>', {
        class: 'btn btn-outline-secondary',
        type: 'button',
        text: 'Enter'
    })

    $divContainer = $('<div/>', {
        class: 'builder-config-option input-group'
    })

    $divContainer
        .append(this.$input)
        .append($button)

    this.$container
        .append($divContainer)
        .parent()
        .fadeIn()

    this.$input.on('change', this.run.bind(this))
}

CableBuilderInputStep.prototype.saveAndDisableInput = function() {
    customCableData[this.modelName][this.optionName] = this.$input.val()
    this.$input.prop('disabled', 'disabled');
}
