EventBus.subscribe('modules-initialised', function () {

    var isTouchDevice =
        'ontouchstart' in window ||
        navigator.MaxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    ; 
    
    isTouchDevice ? EventBus.publish('isTouchDevice') : EventBus.publish('isNotTouchDevice') ;
    
});