/*
    Requirements:
    - jQuery
    - events.js
    - last.js
    - utilities/touch-devices/detection.js
    - https://github.com/paulirish/matchMedia.js/
*/
var NavigationModule = function () {
    
    var self = this;
    
    /**
     * Whether the user is on a touch device
     * @type {boolean}
     */
    self.isTouchDevice = false;
    
    /**
     * The screen width in which mobile navigation kicks in
     * @type {integer}
     */
    self.breakPoint = typeof navigationBreakpoint == 'undefined' ? 991 : navigationBreakpoint;
    
    /**
     * Whether the screen dimensions are within the mobile breakpoint
     * @type {boolean}
     */
    self.isMobileBreakpoint = null;
    
    /**
     * Initialise module
     */
    self.init = function () {
        
        EventBus.subscribe('isTouchDevice', function () {
            self.isTouchDevice = true;
            self.setMobileBreakpoint();
        });
        
        EventBus.subscribe('isNotTouchDevice', function () {
            self.isTouchDevice = false;
            self.setMobileBreakpoint();
        });
        
        jQuery(window).resize(self.setMobileBreakpoint);
        
    };
    
    /**
     * Apply navigation functionality according to touch device functionality and screen size
     */
    self.update = function () {
        
        var
            doc = jQuery(document),
            html = jQuery('html')
        ;
        
        //Update html tag class to reflect mobile breakpoint
        self.isMobileBreakpoint ? html.addClass('wb-nav-mobile').removeClass('wb-nav-desktop') : html.removeClass('wb-nav-mobile').addClass('wb-nav-desktop');
        
        //Clear open list items
        jQuery('.wb-navigation .open > ul').css('left', '').css('right', '');
        jQuery('.wb-navigation :not(.nested-dropdown).open').removeClass('open');
        
        //Logic for when the browser is a touch device or mobile navigation is showing
        if (self.isTouchDevice || self.isMobileBreakpoint) {
            
            //Return if touch class already applied
            if (html.hasClass('wb-nav-touch')) {
                return;
            }
            
            //Update html tag class to reflect touch functionality
            html.addClass('wb-nav-touch').removeClass('wb-nav-notouch');
            
            //Apply touch device functionality
            doc.on('click', '.wb-navigation button', self.dropDownClick);
            
            //Disable non touch device functionality
            doc.off('mouseenter', '.wb-navigation .has-dropdown', self.parentHoverIn).off('mouseleave', '.wb-navigation .has-dropdown', self.parentHoverOut);
            
        }
        else {
            
            //Return if notouch class already applied
            if (html.hasClass('wb-nav-notouch')) {
                return;
            }
            
            //Update html tag class to reflect no touch functionality
            html.removeClass('wb-nav-touch').addClass('wb-nav-notouch');
            
            //Apply non touch device functionality
            doc.on('mouseenter', '.wb-navigation .has-dropdown', self.parentHoverIn).on('mouseleave', '.wb-navigation .has-dropdown', self.parentHoverOut);
            
            //Disable touch device functionality
            doc.off('click', '.wb-navigation button', self.dropDownClick);
            
        }
        
    };
    
    /**
     * Handle clicking of dropdown button
     */
    self.dropDownClick = function () {
        
        var
            clicked = jQuery(this),
            parent = clicked.closest('.has-dropdown')
        ;
        
        if (parent.hasClass('open')) {
            self.resetDropdownPosition(parent);
            parent.removeClass('open');
        }
        else {
            self.positionDropDown(parent);
            parent.addClass('open').siblings().removeClass('open');
        }
        
    };
    
    /**
     * A parent navigation item has been hovered over
     */
    self.parentHoverIn = function () {
        var item = jQuery(this);
        self.positionDropDown(item);
        item.addClass('open');
    };
    
    /**
     * A parent navigation item has been hovered out
     */
    self.parentHoverOut = function () {
        var item = jQuery(this)
        
        if (item.hasClass('nested-dropdown')) {
            return;
        } else {
            self.resetDropdownPosition(item);
            item.removeClass('open');
        }
    };
    
    /**
     * Position an opened dropdown according to whether it's left or right of screen center
     * @param item
     */
    self.positionDropDown = function (item) {
        
        //Cancel if within mobile breakpoint
        if (self.isMobileBreakpoint) {
            return;
        }
        
        if (item.offset().left + (item.outerWidth() / 2) > (jQuery(window).width() / 2)) {
            //Position dropdown to the right edge of its parent if its parent is right of screen center
            item.children('ul').css('right', 0).css('left', '').addClass('positioned-right').removeClass('positioned-left');
        }
        else {
            //Position dropdown to the left edge of its parent if its parent is left of screen center
            item.children('ul').css('left', 0).css('right', '').addClass('positioned-left').removeClass('positioned-right');
        }

    };
    
    /**
     * Reset the dropdown position of navigation item
     * @param item
     */
    self.resetDropdownPosition = function (item) {
        item.children('ul').css('left', '').css('right', '').removeClass('positioned-right').removeClass('positioned-left');
    };
    
    /**
     * Set whether the screen is at mobile breakpoint and call update if need be
     */
    self.setMobileBreakpoint = function () {
        
        var oldBreakpoint = self.isMobileBreakpoint;
        self.isMobileBreakpoint = window.matchMedia('(max-width: ' + self.breakPoint + 'px)').matches;
        
        if (self.isMobileBreakpoint !== oldBreakpoint) {
            self.update();
        }
        
    }
    
    self.init();

};

EventBus.subscribe('init-modules', function () {
    new NavigationModule();
});