var SelectPowerCurrentMax = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'current_max'
    this.title = 'Select max current'
    this.modelName = 'connector_one'
}

SelectPowerCurrentMax.prototype = Object.create(CableBuilderStep.prototype)

SelectPowerCurrentMax.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'cable_function',
            value: customCableData[this.modelName].cable_function
        },
        {
            field: 'contact_type',
            value: customCableData[this.modelName].contact_type
        },
        {
            field: 'number_of_contacts',
            value: customCableData[this.modelName].number_of_contacts
        }
    ]
}
