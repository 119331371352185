var locale = (location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : '';

if (location.href.indexOf('www') < 0 && location.hostname === 'bulgin.com'){
    location.href = location.href.replace('//bulgin', '//www.bulgin');
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    
    for(var i = 0; i <ca.length; i++) {
        
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    
    }
    return "";
}

function setCookie(name, value, days) {  
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = '; expires=' + date.toGMTString();
    document.cookie = name + '=' + value + expires + ';path=/';
  }

  function getParam(p) {  
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

   var gclid = getParam('gclid');

   if(gclid) {
       setCookie('gclid', gclid, 365);
    }

function getCountryFromLocale(locale) {

    var
        locale = locale || 'United States';

    switch(locale) {
        case 'us':
            return 'United States';
            break;
        case 'ca_fr':
            return 'Canada';
            break;
        case 'en':
            return 'United Kingdom';
            break;
        case 'fr':
            return 'France';
            break;
        case 'de':
            return 'Germany';
            break;
        case 'es':
            return 'Spain';
            break;
        case 'it':
            return 'Italy';
            break;

        case 'au':
            return 'Australia';
            break;
        case 'zh_hans':
            return 'China';
            break;
        case 'ja':
            return 'Japan';
            break;

        default:
            return 'United States';
            break;
    }
}

function getRegionFromLocale(locale) {

    var 
        locale = locale || 'us';

    switch(locale) {
        case 'us':
        case 'ca_fr':
        case 'ca-fr':
            return 'The Americas';
        break;

        case 'en':
        case 'fr':
        case 'de':
        case 'es':
        case 'it':
            return 'EMEA';
        break;

        case 'au':
        case 'zh_hans':
        case 'zh-hans':
        case 'ja':
            return 'Asia Pacific';
        break;

        default:
            return 'The Americas';
        break;
    }
}

function isInViewport(selector) {
    var
        elementTop = $(selector).offset().top,
        elementBottom = elementTop + $(selector).outerHeight(),
        viewportTop = $(window).scrollTop(),
        viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

function formatNumber(num) {
    while (/(\d+)(\d{3})/.test(num.toString())) {
        num = num.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
    }
    return num;
}

var isCounterFinished = false,
    breadcrumbOffsetLeft = 0,
    breadcrumbOffsetTop = 50;
var translate = { 
    showing: ["showing"],
    of: ["of"]
}

function startCounter() {
    if (isCounterFinished) {
        return;
    }
    $('.counter').each(function() {
        $(this).prop('Counter', 0).animate({
            Counter: $(this).data('count')
        }, {
            duration: 3500,
            easing: 'swing',
            step: function(now) {
                $(this).text(formatNumber(Math.ceil(now)));
            }
        });
    });
}

var maxHeight = 0;

$(".height-sync").each(function(){
   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
});

$(".height-sync").height(maxHeight);

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

$(document).ready(function(){
    var 
        scrollOnLoad = sessionStorage.getItem("scroll-to")
    ;
    if (scrollOnLoad !== null && scrollOnLoad.length > 0) {
        scrollConfig = JSON.parse(scrollOnLoad);
        
        var target = jQuery(scrollConfig.target);

        if (target.length > 0) {
            $('html, body').animate({
                scrollTop: target.offset().top + scrollConfig.offset
            }, 1000);

            sessionStorage.removeItem("scroll-to")
        }
    }

});


$(document).ready(function() {

    $('body').on('click', '.is-product', function(e) {
        var classList = e.target.classList.value;
        var found = classList.search(/button/i);
        var link = $(e.currentTarget).data('link');

        if(found < 0 && link) {
            window.location.href = link;
        }
    });

    //my products counter
    $.ajax({
        url: '/products/bulginHelper/index/cart',
        method: "GET",
        success: function(res) {
            if (res.length > 0) {
                var txt = $('#myproducts-nav').text();
                $('#myproducts-nav').html(txt + '&nbsp;<sup class="myproducts-counter">(' + pad(res.length, 2) + ')</sup>');
            }
        },
        dataType: 'json'
    });

    var 
        scrollLink = $('.scroll'),
        scrollOffset
    ;

    scrollOffset = scrollLink.data('scroll-offset') !== undefined ? scrollLink.data('scroll-offset') : 0;

     // Smooth scrolling
    scrollLink.click(function(e) {
        e.preventDefault();
        $('body,html').animate({
            scrollTop: $(this.hash).offset().top + scrollOffset
        }, 1000);
    });
    
    // Active link switching

    $(window).scroll(function() {
        var scrollbarLocation = $(this).scrollTop();

        scrollLink.each(function() {

            var sectionOffset = $(this.hash).offset().top - 20;

            if (sectionOffset <= scrollbarLocation) {
                $(this).parent().addClass('active');
                $(this).parent().siblings().removeClass('active');
            }
        });

    });

    //parallax
    var ua = window.navigator.userAgent;
    var isIE = /MSIE|Trident|Edge/.test(ua);

    var sizeVideo = function() {
        var $video = $('#myVideo'),
            videoCompwidth = $(window).width(),
            videoCompHeight = $(window).height(),
            videoWidth = $video.width(),
            videoHeight = $video.height(),
            videoLeft,
            vidRatio = (videoHeight / videoWidth),
            compRatio = (videoCompHeight / videoCompwidth);

        if (compRatio > vidRatio) {
            $video.css('width', 'unset');
            $video.css('height', $videoCompHeight+'px');
        } else {
            $video.css('height', 'unset');
            $video.css('width', $videoCompwidth+'px');
        }

        if (videoWidth > videoCompwidth) {
            videoLeft = -((videoWidth - videoCompwidth) / 2);
        } else {
            videoLeft = 0;
        }

        $video.css('left', $videoLeft+'px');
    }

    if($(window).width() > 1197 && (!isIE)) {
            $(".banner.full-screen, .banner.condensed").each(function (i) {
                if (i === 0) {
                    $('body').addClass('has-parallax');
                    $('.banner-content').wrapInner('<div class="banner-rellax"></div>');
                    $('.banner-rellax').addClass('rellax').attr('data-rellax-speed', '-6');
                    if($('#myVideo').length > 0) {
                        $('.video-overlay').css('background-attachment','fixed');
                        $('#myVideo').css('position','fixed');
                        $('#myVideo').css('top','0px');
                        $('#myVideo').css('width','100%');
                        $('#myVideo').css('height','100%');
                        $('#myVideo').css('object-fit','cover');
                        $('#myVideo').css('z-index','-9999');

                        //move to main-content
                        $('#myVideo').appendTo('#main-content');


                    }
                }
            });
    }

    var rellax = new Rellax('.rellax', {
        callback: function(position) {

        }
    });


});

$(window).scroll(function() {
    var header = $('header'),
        logo,
        banner,
        stickymenuoffset;
    logo = header.find('.sticky-logo img');
    stickymenuoffset = 56;
    var localeBanner = $('#banner-locale');
    if ($('.banner-inner').length) {
        banner = ($('.banner-inner').offset().top + $('.banner-inner').height()) + (stickymenuoffset * 2);
    } else {
        banner = 0;
    }
    if($(window).width() > 1197){
        if(localeBanner) {
            $(header).css('top',$(localeBanner).outerHeight()+'px');
        }
        if ($(this).scrollTop() > banner) {
            if (!header.hasClass('sticky') && $('header').data('animating') !== true) {
                $('header').data('animating', true);
                header.addClass("sticky").hide().slideDown(400, function() {
                    $(this).data('animating', false);
                    $(this).show();

                    if ( $(window).width() >= 1199) {
                        logo.show();
                    }

                });
                logo.parent('div').removeClass('col-xl-9').addClass('col-xl-12');
            }
        } else {
            if (header.hasClass('sticky') && $('header').data('animating') !== true) {
                $('header').data('animating', true);

                logo.fadeOut(200);

                $('header').slideUp(400, function() {
                    $(this).removeClass("sticky");
                    logo.parent('div').removeClass('col-xl-12').addClass('col-xl-9');
                    $(this).data('animating', false).show();
                    $(header).css('top','0px');
                });
            }
        }
    }

    if ($('.counter').length > 0 && isInViewport('.counter')) {
        startCounter();
    }

    if ($('.lang-dropdown').is(':visible')) {
        $('.lang-dropdown').hide();
    }

    if ($(this).scrollTop() > 150) {
        $('.bread-crumbs').css('visibility', 'hidden');
    } else {
        if ($('.search-value').is(':hidden')) {
            $('.bread-crumbs').css('visibility', 'visible');
        }
    }
    /**var pos = $('.navbar-nav').find('li').eq(0).find('a').eq(0).offset();
    $('.bread-crumbs').css({
        top: pos.top + breadcrumbOffsetTop,
        left: pos.left + breadcrumbOffsetLeft,
        position: 'absolute'
    });
    setTimeout(function() {
        var pos = $('.navbar-nav').find('li').eq(0).find('a').eq(0).offset();
        $('.bread-crumbs').css({
            top: pos.top + breadcrumbOffsetTop,
            left: pos.left + breadcrumbOffsetLeft,
            position: 'absolute'
        });
    }, 500);**/
});

$(document).ready(function() {
    var
        slider = $('#slider'),
        slides = slider.find('.carousel-item'),
        previousArrow = $('.carousel-previous'),
        nextArrow = $('.carousel-next');
        
    if($('.newsletter-modal').is(':visible')) {
        $.getScript('https://js.hsforms.net/forms/v2.js', function(){
                hbspt.forms.create({
              portalId: "4508196",
              formId: "0ddf77c5-1a6c-46aa-9662-677be60a14fc",
              target:".newsletter-modal"
          });

        });
    }

    function getHubspotFormFromLocale(locale) {

        switch(locale) {
           case "en":
           case "us":
           case "au":
              
              return "d7db0ecb-5b3c-4475-9125-c2032c2402ed"; 

           break;

           case "fr":
           case "ca_fr":
           case "ca-fr":

               return "90edc227-08b0-4d5a-8139-f7c4b5c9ceba";

           break;

           case "it":
               return "372f9a2c-9c61-4660-b6d9-7171243cff54";
           break;

           case "de":
              return  "a3bb7f74-903b-44e3-b8c2-a489ccff6afc";
           break;

           case "es":
               return "7e764946-1c07-4e22-8572-c2955112b878";
           break;

           case "ja":
               return "7fd20b66-3c7a-49a4-84fb-73ba898e726e";
           break;

           case "zh_hans":
           case "zh-hans":
               return "a51694da-7ab3-4545-9a11-91706a33a97c";
           break;

           default:
               return "7ffd4ff4-b928-4df3-9308-e101066d0a4f";
           break;

        }
    }

    if( $('#contact-us-form').length > 0) {     
        hbspt.forms.create({
          portalId: "4508196",
          formId: getHubspotFormFromLocale(locale),
          target:"#contact-us-form",
          onBeforeFormInit: function(ctx) {
              $.getScript('https://cdn2.hubspot.net/hubfs/4508196/assets/form-script-2020.js').done(function(){
                  var load_event = new Event("load"); 
                  window.dispatchEvent(load_event);    
              });   
          }
        });
  }
  
  // if($('#contact-us-form').length || $('#custom-enquiry-form').length) {
      // turn on cache
      // $.ajaxSetup({ cache: true });
      // $.getScript('https://cdn2.hubspot.net/hubfs/4508196/assets/form-script-2020.js');
      // turn cache back off
      // $.ajaxSetup({ cache: false });
  // }

    // Downloads function to pull the scroll down to actual downloads  
    if($('.download-item').length && $('.full-screen').length && $('.hover-state').length){
        if($('.hover-state').find('.col-md-6').length && !$('.hover-state').hasClass('no-scroll-on-load')){ //Check this is the downloads page not industries
            $('html, body').animate({
            scrollTop: $('.download-item').offset().top - 100
          }, 800);
        }
    }

    slides.first().addClass('active');

    slider.on('slid.bs.carousel', function() {
        var
            currentIndex = slider.find('.carousel-item.active').data('slide-index');
        currentIndex == 0 ? previousArrow.hide() : previousArrow.show();
        currentIndex == slides.length - 1 ? nextArrow.hide() : nextArrow.show();

    });

    var validateForm = function(type) {
        if(type === 'mobile') {
            $('.search-error-msg').remove();
            if($('.search-m-value').val().length < 3) {
                $('.flyout-search').append('<p class="d-flex justify-content-end text-warning position-absolute text-danger font-size-14-px search-error-msg">Search at least 3 characters</p>');
                $('.search-error-msg').css({
                    'top': '30px',
                    'left': '20px',
                    'font-size': '12px'
                });
            } else {
                window.location.href = $('.search-m-value').data('url') + $('.search-m-value').val();
            }
        }
        if(type === 'desktop') {
            $('.search-error-msg').remove();
            if($('.search-value').val().length < 3) {
                $('.placeholders-white').append('<p class="d-flex justify-content-end text-warning position-absolute text-danger font-size-14-px search-error-msg">Search at least 3 characters</p>');
                $('.search-error-msg').css({
                    'left': '10px',
                    'top': '40px'
                });
            } else {
                window.location.href = $('.search-value').data('url') + $('.search-value').val();
            }
        }
    }

    $('.search-value').keypress(function(e) {
        if (e.which === 13) {  // Enter key is pressed
            e.preventDefault();
            validateForm('desktop');
        }
    });

    $('.search-m-value').keypress(function(e) {
        if (e.which === 13) {
            e.preventDefault();
            validateForm('mobile');
        }
    });

    $('.search-submit-btn').on("click", function(e) {
        e.preventDefault();
        validateForm('desktop');
    });

    $('#desktop-search').on("submit", function(e) {
        e.preventDefault();
        validateForm('desktop');
    });
    $('#mobile-search').on("submit", function(e) {
        e.preventDefault();
        validateForm('mobile');
    });

    $('.submenu').on("click", function(event) {
        event.preventDefault();
        var submenu_id = $(this).attr('data-submenu');

        if ($('#side-submenu-' + submenu_id).is(":visible")) {
            $('#chevron-' + submenu_id).css({
                'transition': 'transform 0.2s',
                '-webkit-transform': 'rotate(0deg)',
                '-moz-transform': 'rotate(0deg)',
                '-ms-transform': 'rotate(0deg)',
                'transform': 'rotate(0deg)'
            });
        } else {
            $('#chevron-' + submenu_id).css({
                'transition': 'transform 0.2s',
                '-webkit-transform': 'rotate(180deg)',
                '-moz-transform': 'rotate(180deg)',
                '-ms-transform': 'rotate(180deg)',
                'transform': 'rotate(180deg)'
            });
        }
        $('#side-submenu-' + submenu_id).toggle();
    });

    $('#search-icon').on('click', function(event) {
        event.preventDefault();
        $('.navbar-nav').fadeToggle('fast', function() {
            $('.search-box').fadeToggle('fast').toggleClass('d-none');
            if ($('.search-value').is(":visible")) {
                $('.search-value').focus();
                $('.bread-crumbs, .language-box').animate({
                    opacity: 1
                }, 500).css({
                    opacity: 0,
                    visibility: "hidden"
                });
                $('.bread-crumb-container').addClass('d-none');
                $(document).on('click', function searchbox(e) {
                    if ($(e.target).closest('.search-box').length === 0) {
                        if ($('.search-box').is(":visible")) {
                            $('.search-box').fadeToggle('fast', function() {
                                $(this).toggleClass('d-none');
                                $('.navbar-nav').fadeToggle('fast');
                            });
                            if ($(window).scrollTop() == 0 && $(window).height() > 600) {
                                $('.bread-crumbs, .language-box').css({
                                    opacity: 0,
                                    visibility: "visible"
                                }).animate({
                                    opacity: 1
                                }, 500, function(){ $('.bread-crumb-container').removeClass('d-none'); });
                            }
                        }
                        $(document).off('click', searchbox);
                    }

                });
            } else {
                $(document).off('click', searchbox);
            }
        });
    });

    $('#modal-sign-up-form').on('show.bs.modal', function() {
        var
            signupEmailInput = $('#sign-up-email-input'),
            modalEmailInput = $('#modal-email-input');

        if (signupEmailInput.length > 0) {
            modalEmailInput.val(signupEmailInput.val());
        }

    });

    //salesforce oid for forms
    if ( $('input[name="oid"]').length > 0 ){
            $('input[name="oid"]').val('00D58000000JC3Q');
    }

    // add region
    if ($('input#region').length > 0) {
        var region = getRegionFromLocale(locale);
        $('input#region').val(region);
    }
    // checked for spam field
    if ($('select[name="00N58000002FQUH"]').length > 0) {
        $('select[name="00N58000002FQUH"]').find('option[value="Yes"]').attr("selected",true);
    }

    $(".lang-select").click(function(e) {
        if($(this).hasClass('mobile-lang')){
            $(".lang-dropdown").eq(1).show();
        }
        else{
            $(".lang-dropdown").eq(0).show();
        }
        $(document).click(function langdrop() {
            $(".lang-dropdown").hide();
            $(document).off('click', langdrop);
        });
        e.stopPropagation();
    });
    $(".lang-dropdown").click(function(e) {
        e.stopPropagation();
    });

    selectedFileContainer = $("#selectedFiles");


    $('input[type="file"]').on('change', handleFileSelect);
    $(document).on('click', '.sel-file', removeFile);

    // custom dropdown

    $('.custom-select').each(function() {
        var
            $this = jQuery(this),
            numberOfOptions = jQuery(this).children('option').length;

        $this.hide();
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled form-control"></div>');

        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());

        var $list = jQuery('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect);

        for (var i = 0; i < numberOfOptions; i++) {
            if ($this.children('option').eq(i).val() != '') {
                jQuery('<li />', {
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val()
                }).appendTo($list);
            }
        }

        var $listItems = $list.children('li');

        $styledSelect.click(function(e) {
            e.stopPropagation();
            jQuery('div.select-styled.active').not(this).each(function() {
                $(this).removeClass('active').next('ul.select-options').hide();
            });
            jQuery(this).toggleClass('active').next('ul.select-options').toggle();
        });

        $listItems.click(function(e) {
            e.stopPropagation();
            $styledSelect.text(jQuery(this).text()).removeClass('active');
            $this.val(jQuery(this).attr('rel'));
            $list.hide();
        });

        jQuery(document).click(function() {
            $styledSelect.removeClass('active');
            $list.hide();
        });


    });

  jQuery('[name="accept_contact"]').on('click', function() {
        if (jQuery(this).is(":checked")) {
            jQuery('#accept-contact-error').hide('slow');
        }
    });

    jQuery('input[type="submit"]').on('click', function(){

        var 
            form = jQuery(this).closest('form'),
            select = form.find('select')
        ;    
        jQuery(select).each(function(i, el) {
            if (jQuery(el).prop('required') && jQuery(el).children("option:selected").val() == '') {
                jQuery(el).parent().addClass('error');
            } else {
                jQuery(el).parent().removeClass('error');
            }
        });
        
        if (jQuery('[name="accept_contact"]').is(':checked') == false) {
            jQuery('#accept-contact-error').show();
        }
    });
    
    $('.wp-form .selected').on('click', function (e) {

        if (jQuery(this).next().hasClass("select-hidden")) {
            jQuery('.select-box').find('ul').addClass('select-hidden');
            jQuery(this).next().removeClass('select-hidden');
        } else {
            jQuery(this).next().addClass('select-hidden');
        }

    });
    $('form .select-dropdown li').on('click', function() {
        var 
            dropDown = $(this).closest('.select-box'),
            dropDownTarget = jQuery(dropDown.data('target'))
        ;
        
        dropDown.removeClass('error');
        dropDownTarget.removeClass('error');

        $(this).addClass('active').siblings().removeClass('active');
        
        var dropDownLabel = $(this).attr('title');

        dropDown.find('.filter-label').text(dropDownLabel);

        $(this).parent().delay(500).addClass('select-hidden');


        var dropDownValue = $(this).attr('value');

        $(dropDownTarget).val(dropDownValue).attr('selected', 'selected');
        
    

    });

});

// job form files

var storedFiles = [],
    selectedFileContainer = "";

function handleFileSelect(e) {
    jQuery('#document-upload-error').removeClass('show');
    
    // disabled input when 2 files have been added
    if (storedFiles.length > 1) {
        $('input#attachments').attr('disabled', "disabled");
        return;
    }

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    filesArr.forEach(function(file) {

        storedFiles.push(file);

        var reader = new FileReader();
        reader.onload = function(e) {
            var html = "<div class='d-block text-left'><span data-file='" + file.name + "' class='white-text sel-file' title='Click to remove'>" + file.name + "<span class='pl-1 remove-file'>X</span>" + "<br clear=\"left\"/></span></div>";
            selectedFileContainer.append(html);

        }
        reader.readAsDataURL(file);
    });

}

function removeFile(e) {
    var
        self = $(this),
        file = self.data('file');

    for (var i = 0; i < storedFiles.length; i++) {
        if (storedFiles[i].name === file) {
            storedFiles.splice(i, 1);
            break;
        }
    }

    if (storedFiles.length < 2) {
        $('input#attachments').removeAttr('disabled').val('');
    }
    self.remove();
}


$(".toggle-nav").on('click', function(e) {
    if (parseInt($('#sideNav').css('right')) == -380) {
        $('#main-content').animate({
            'right': 380
        }, {
            duration: 200,
            queue: false
        });
        $('#sideNav').animate({
            'right': 0
        }, {
            duration: 200,
            queue: false
        });
        
        // Attach document click event to close the sideNav when clicking outside of it
        $(document).on('click', function(e) {
            if ($(e.target).closest('#sideNav').length === 0) {
                closeSideNav();
            }
        });
    }
});

// Event listener for the "close-nav" button
$(".close-nav").on('click', function(e) {
    closeSideNav();
});

// Function to close the side navigation
function closeSideNav() {
    if (parseInt($('#sideNav').css('right')) == 0) {
        $('#main-content').animate({
            'right': 0
        }, {
            duration: 200,
            queue: false
        });
        $('#sideNav').animate({
            'right': -380
        }, {
            duration: 200,
            queue: false
        });
    }
}

$(document).ready(function() {

    if($('video').length){
        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/object-fit-images/3.2.4/ofi.min.js", function(){ objectFitImages(); });
    }


    $('#btn-cookie-allow').click(function(e){
        e.preventDefault();
        var host = window.location.host.replace('www.','');
        document.cookie = "user_allowed_save_cookie=%7B%224%22%3A1%7D;expires=Fri, 31 Dec 9999 23:59:59 GMT;domain="+host+";path=/";
        $(this).closest('#notice-cookie-block').remove();
        localStorage.setItem("cookie", true);
    });

    if(localStorage.cookie){
        var host = window.location.host.replace('www.','');
        document.cookie = "user_allowed_save_cookie=%7B%224%22%3A1%7D;expires=Fri, 31 Dec 9999 23:59:59 GMT;domain="+host+";path=/";
        $('#notice-cookie-block').remove();
    }

    $('.autogrow').autoGrowInput({
        minWidth: 400,
        maxWidth: ($(window).width() - 250),
        comfortZone: 60
    });


    var header = $('header');
    logo = header.find('.sticky-logo');

    if ($(window).scrollTop() > 0) {
        if (!header.hasClass('sticky')) {
            header.addClass("sticky").hide().slideDown();
        }
        logo.parent('div').removeClass('col-xl-9').addClass('col-xl-12');
    } else {
        $('header').removeClass("sticky");
        logo.parent('div').removeClass('col-xl-12').addClass('col-xl-9');
    }


    function offsetAnchor() {
       if(location.hash.length !== 0) {
	  if($('header').hasClass('sticky')){
		window.scrollTo(window.scrollX, window.scrollY - (100 + $('header').height()));
	  }
	  else{
          	window.scrollTo(window.scrollX, window.scrollY - 100);
	}
       }
    }

    window.addEventListener("hashchange", offsetAnchor);

    window.setTimeout(offsetAnchor, 1);


    if ($('.counter').length > 0 && isInViewport('.counter')) {
        startCounter();
    }
    var i = 0;
    $('.submenu').each(function() {
        i++;
        $(this).append("<i class=\"icon-down-open\" style=\"float: right;\" id=\"chevron-" + i + "\"></i>");
    });

    if ($('.alm-btn-wrap').length) {
        $('.alm-btn-wrap').prepend("<strong>" + translate.showing[0] + " <span id='shown-posts'>6</span> " + translate.of[0] + " <span id='total-posts'>" + $('#firstScroll').attr('data-total-posts') + "</span><br /><br />");

        $('.alm-load-more-btn').on('click', function() {
            $('#shown-posts').fadeOut(function() {
                if ((parseInt($('#shown-posts').text()) + 6) < $('#firstScroll').attr('data-total-posts')) {
                    $(this).text(parseInt($('#shown-posts').text()) + 6).fadeIn();
                    if ((parseInt($('#shown-posts').text()) + 6) > parseInt($('#firstScroll').attr('data-total-posts'))) {
                        var moretoload = parseInt($('#firstScroll').attr('data-total-posts')) - parseInt($('#shown-posts').text());
                        $('.alm-load-more-btn').text('Load ' + moretoload + ' More');
                    }
                } else {
                    $(this).text($('#firstScroll').attr('data-total-posts')).fadeIn();
                    $('.alm-load-more-btn').remove();
                }
            });
        });
    }
    /**var pos = $('.navbar-nav').find('li').eq(0).find('a').eq(0).offset();
    $('.bread-crumbs').css({
        top: pos.top + breadcrumbOffsetTop,
        left: pos.left + breadcrumbOffsetLeft,
        position: 'absolute'
    });**/
    if ($(this).scrollTop() == 0 && $(this).height() > 600 && $('.search-value').is(':hidden')) {
        $('.bread-crumbs').css('visibility', 'visible');
    }

    /**setTimeout(function() {
        var pos = $('.navbar-nav').find('li').eq(0).find('a').eq(0).offset();
        $('.bread-crumbs').css({
            top: pos.top + breadcrumbOffsetTop,
            left: pos.left + breadcrumbOffsetLeft,
            position: 'absolute'
        });
    }, 500);**/

    $(window).on('resize', function() {
        if ($('.search-value').is(":visible")) {
            $('.search-box').fadeToggle('fast', function() {
                $(this).toggleClass('d-none');
                $('.navbar-nav').fadeToggle('fast');
            });
            if ($(window).scrollTop() == 0 && $(window).height() > 600) {
                $('.bread-crumbs, .language-box').css({
                    opacity: 0,
                    visibility: "visible"
                }).animate({
                    opacity: 1
                }, 500);
            }
        }

        var pos = $('.navbar-nav').find('li').eq(0).find('a').eq(0).offset();
        if ($(this).scrollTop() > 0 || $(this).height() < 600) {
            $('.bread-crumbs').css('visibility', 'hidden');
        } else {
            if ($('.search-value').is(':hidden')) {
                $('.bread-crumbs').css('visibility', 'visible');
            }
        }
        /**$('.bread-crumbs').css({
            top: pos.top + breadcrumbOffsetTop,
            left: pos.left + breadcrumbOffsetLeft,
            position: 'absolute'
        });**/

        if ($(window).width() > 1197 && parseInt($('#sideNav').css('right')) == "0") {
            $('#main-content').animate({
                'right': 0
            }, {
                duration: 200,
                queue: false
            });
            $('#sideNav').animate({
                'right': -250
            }, {
                duration: 200,
                queue: false
            });
        }
    });

    //related products paging
    var per_page = 4;
    var current_page = 1;
    var start_page = 1;
    var current_set;
    var related_products;

    $('body').on('click', '.related-next', function(e) {
        e.preventDefault();
        console.log(e);
        pageResults('next');
    });

    $('body').on('click', '.related-prev', function(e) {
        e.preventDefault();
        pageResults('prev');
    });

    $('body').on('click', '.related-product-tile .cad', function(e) {
        e.preventDefault();
        var link = $(this).attr('href');
        if(link.indexOf('reference') > 0) {
            var part = link.split("=");
            link = 'https://www.traceparts.com/els/bulgin/en/api/viewer/3d?SupplierID=BULGIN&PartNumber='+part[1];
        }
        $('.simple-modal-content').html('<iframe src="' + link + '" height="500" width="100%"></iframe>');
        $("#cad-simple-product").modal('show');

    });

    $('body').on('click', '.sk-toggle-option', function(e) {
        e.preventDefault();
        if ($(e.currentTarget).data('page')) {
            start_page = $(e.currentTarget).data('page');
            current_page = $(e.currentTarget).data('page');

            populatePage();
            updateNav();
            render();
            var additionalOffset = 70;
            if($(window).width() > 1197) {
                additionalOffset = 150;
            }
            window.jQuery('body,html').animate({
                scrollTop: window.jQuery('.related-list-items').offset().top - additionalOffset
            }, 1000);

        }
    });

    function setTotal() {
        $('.related-total').text(related_products.length);
    }

    function totalNumPages() {
        var total = Math.round(related_products.length / per_page);
        var totalDisplay = (total > 0) ? total : 1;
        return totalDisplay;
    }

    function render() {

        $('.related-list-items').empty();
        for (var i = 0; i < current_set.length; i++) {
            $('.related-list-items').append(current_set[i]);
        }
    }

    function pageResults(dir) {
        if (dir === 'next') {
            if (current_page !== totalNumPages()) {
                current_page = current_page + 1;
            }
        } else {
            if (current_page !== 1) {
                current_page = current_page - 1;
            }
        }
        populatePage();
        updateNav();
        render();
    }

    function startFirstPage() {
        current_page = start_page;
        current_set = related_products.slice(0, per_page);
    }

    function populatePage() {
        var start;
        var end;
        if (current_page === 1) {
            start_page = 1;
            startFirstPage();
            return;
        }
        start = ((current_page - 1) * per_page);
        end = ((current_page - 1) * per_page) + per_page;
        current_set = related_products.slice(start, end);
    }

    function updateNav() {
        if (totalNumPages() <= 1) {
            $('.related-list-paging').addClass('d-none');

        } else {
            $('.related-list-paging').removeClass('d-none');
            setPages();
            setTotal();
        }
        (current_page === 1) ?
            $('.related-prev').addClass('is-disabled'): $('.related-prev').removeClass('is-disabled');
        (current_page === totalNumPages()) ?
            $('.related-next').addClass('is-disabled'): $('.related-next').removeClass('is-disabled');

    }

    function setPages() {
        var pages = [];
        if((totalNumPages() <= 5)) {

            for(var i=1; i <= totalNumPages(); i++) {
                pages.push(i);
            }

        } else {
            if (totalNumPages() > 5 && (totalNumPages() - current_page) < 3) {
                pages.push(1);
                pages.push('...');
            }
            if ((current_page - 2) > 0) {
                pages.push(current_page - 2);
            }
            if ((current_page - 1) > 0) {
                pages.push(current_page - 1);
            }

            pages.push(current_page);
            if ((current_page + 1) <= totalNumPages()) {
                pages.push(current_page + 1);
            }
            if ((current_page + 2) <= totalNumPages()) {
                pages.push(current_page + 2);
            }
            if ((totalNumPages() > 5) && current_page !== totalNumPages() && current_page < totalNumPages() - 2) {
                pages.push('...');
                pages.push(totalNumPages());
            }
        }

        var activeClass = ' is-active';
        var content = pages.reduce(function(str, item, i, arr) {
            if (i === 1) {
                var current = (arr[i - 1] === current_page) ? activeClass : '';
                str = "<p class='sk-toggle-option" + current + "' data-page='" + arr[i - 1] + "'><strong><span>" + arr[i - 1] + "</span></strong></p>";
            }
            if (arr[i] === '...') {
                str = str + "<p class='sk-toggle-option ellipsis'><strong><span>" + arr[i] + "</span></strong></p>";
            } else {
                var current = (arr[i] === current_page) ? activeClass : '';
                str = str + "<p class='sk-toggle-option" + current + "' data-page='" + arr[i] + "'><strong><span>" + arr[i] + "</span></strong></p>";
            }
            return str;
        });

        $('.related-pages').html(content);
    }

    if ($('.related-product-tile').length > 1) {
        var $container = $('.related-product-tile').parents('.container');
        var $row = $container.find('.row');
        var children = $row.children();
        if (children.length > 4) {
            related_products = children;
            $row.addClass('related-list-items');
            $container.after('<div class="container container-related-paging"><div class="text-center padding-45-top font-size-16-px related-list-paging d-none">'+
                    '<strong><a class="black-text related-prev" href="#">&lt;</a>     <span class="related-pages"></span>    <a href="#" class="black-text related-next">&gt;</a> <span class="float-right related-total-text"><span class="related-total"></span> Item(s)</span></strong>'+
            '</div></div>');
            startFirstPage();
            updateNav();
            render();
        }
    }

  if($('.hover-state').length){
    if($('.download-item').length){
        $('.download-item').hover(function(){ $(this).parents('.hover-state').find('.download-item').addClass('inactive').removeClass('active'); $(this).addClass('active'); }, function(){ $(this).parents('.hover-state').find('.download-item').removeClass('inactive'); $(this).removeClass('active'); });
    }
    if($('.related-product-tile').length){
        $('.hover-state').on('mouseover', '.related-product-tile', function(){ $(this).parents('.hover-state').find('.related-product-tile').addClass('inactive').removeClass('active'); $(this).addClass('active'); }); 
        
        $('.hover-state').on('mouseout', '.related-product-tile', function(){ $(this).parents('.hover-state').find('.related-product-tile').removeClass('inactive'); $(this).removeClass('active'); });
    }
    if($('.download-list-item').length){
        $('.download-list-item').hover(function(){ $(this).parents('.hover-state').find('.download-list-item').addClass('inactive').removeClass('active'); $(this).addClass('active'); }, function(){ $(this).parents('.hover-state').find('.download-list-item').removeClass('inactive'); $(this).removeClass('active'); });
    }
    if($('.hover-item').length){
        $('.hover-item').hover(function(){ $(this).parents('.hover-state').find('.hover-item').addClass('inactive').removeClass('active'); $(this).addClass('active'); }, function(){ $(this).parents('.hover-state').find('.hover-item').removeClass('inactive'); $(this).removeClass('active'); });
    }
  }



});

function parse_query_string(query, splitter) {
  var splitter = splitter || '&'
  var vars = query.split(splitter);
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      var arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

  //Read more toggle moved here and re-factored because it was causing an exception

    var
        readMoreToggle = $('.read-more-toggle'),
        hasReadMore = readMoreToggle.length > 0
    ;
    
    if (hasReadMore) {
        
        var
            lineCount = readMoreToggle.data('linecount'),
            more = readMoreToggle.data('read-more-text'),
            less = readMoreToggle.data('read-less-text')
        ;
        
        var applyReadMore = function () {
            readMoreToggle.moreLines({
                linecount: lineCount > 0 ? lineCount : 5.5,
                baseclass: 'b-description',
                basejsclass: 'js-read-more',
                classspecific: '_readmore',    
                buttontxtmore: more.length > 0 ? more : 'Read More',               
                buttontxtless: less.length > 0 ? less : 'Read Less',               
                animationspeed: 250 
            });
        };
        
        applyReadMore();
        
    }

    //Video click
    $(document).on('click', '.video-list-item', function (e) {
        
        e.preventDefault();
        
        //Get clicked video
        var clicked = $(this);
        
        //Update title
        var title = clicked.data('title');
        $('#featured-video-title').html(title);
        
        //Update description
        $('#video-description').html(clicked.data('description'));
        
        //Update iframe
        var frame = $('#featured-video-frame');
        frame.attr('src', clicked.data('embed'));
        
        //Handle read more
        if (hasReadMore) {
            applyReadMore();
        }
        
        //Update URL
        window.history.replaceState('string', title, clicked.data('url'));
        
        //Scroll to video
        EventBus.publish('scroll-to-target', {
            target: frame,
            offset: $('header.sticky').outerHeight()
        });
        
    });

// videos pagination

var
    videosListContainer = $('.videos-list-container')
;

page = 1;


// disable prev/next when on first/last page

jQuery(document).on('click', '.videos-pagination a', function(e) {

    e.preventDefault();

    var
        clicked = jQuery(this),
        container = jQuery('.videos-pagination'),
        currentPage = container.find('a.active'),
        prev = container.find('a.prev'),
        next = container.find('a.next'),
        type = videosListContainer.data('load-action'),
        postsPerPage = videosListContainer.data('posts-per-page')
    ;
    
        try {
                 query = parse_query_string($(this).attr('href').split('?')[1]);
        }
        catch(err) {
                query = {};
        }



        if ( clicked.hasClass('disabled') || clicked.hasClass('active') ) {
            return;
        }

        container.find('.active').removeClass('active');


        if (clicked.attr('href') == '#') {

            if (clicked.hasClass('prev')) {
                next.removeClass('disabled');
                currentPage = currentPage.parent().prev().find('a').addClass('active');
                container.find('.page-link:first').hasClass('active') ? prev.addClass('disabled') : prev.removeClass('disabled');
                page =  parse_query_string(currentPage.attr('href'), '?')['page_'];
            }
             else {



                prev.removeClass('disabled');

                currentPage = currentPage.parent().next().find('a').addClass('active');
                container.find('.page-link:last').hasClass('active') ? next.addClass('disabled') : next.removeClass('disabled');
                page =  parse_query_string(currentPage.attr('href'), '?')['page_'];

            }

        }else {

            clicked.index('.page-link') == 0 ? prev.addClass('disabled') : prev.removeClass('disabled');
            clicked.addClass('active');
            page = query.page_;

        }

           $('html, body').animate({
            scrollTop: $('.video-list-wrapper').offset().top
          }, 500);



        $.post(ajaxUrl, {
            action: 'load' + type,
            page: page,
            posts_per_page: postsPerPage
        },
        function (response) {
            if (response.data[type].length > 0) {
                videosListContainer.children().fadeOut().remove();
                videosListContainer.append(response.data[type]).fadeIn();
            }
        });


});

jQuery('.scroll-on-load').on('click', function(){
    var 
        self = $(this),
        scrollTarget = self.data('scroll-target'),
        scrollOffset = self.data('scroll-offset') !== undefined ? self.data('scroll-offset') : 0,
        scrollConfig
    ;
        scrollConfig = {'target':scrollTarget, 'offset': scrollOffset};
        sessionStorage.setItem('scroll-to', JSON.stringify(scrollConfig));
});

// custom solutions pagination

if (jQuery('.list-paging .page-item').length > 0) {

    var pages = jQuery('.list-paging .page-item');

    jQuery('.list-paging .page-item').on('click', function(e) {
        var self = jQuery(this),
            container = self.closest('.list-paging'),
            itemsContainer = jQuery(container.data('items-container')),
            total = container.data('total'),
            totalPages = container.data('total-pages'),
            posts_per_page = container.data('posts-per-page'),
            items = jQuery(container.data('items')),
            clicked = self.data('page'),
            current = container.find('.is-active'),
            currentPageNumber = current.data('page'),
            activeClass = 'is-active',
            pageNumberItems = jQuery(container.find('.page-numbers .page-item')),
            isPageLink = true,
            
            start = ((clicked - 1) * posts_per_page),
            end = ((clicked - 1) * posts_per_page) + posts_per_page,
            current_items = items.slice(start, end)
        ;
            if (self.hasClass('disabled')) {
                return;
            }

            if (self.hasClass('prev') || self.hasClass('next')){
                isPageLink =  false;

                if (self.hasClass('prev')) {
                    if (currentPageNumber == 1) {
                        return;
                    }
                    currentPageNumber = currentPageNumber -  1;
                    current = current.prev();
                    
                }
                if (self.hasClass('next')) {
                    if (currentPageNumber == totalPages) {
                        return;
                    }

                    currentPageNumber = currentPageNumber + 1;
                    current = current.next();
                }

                current.addClass(activeClass);
                current.siblings().removeClass(activeClass);

                start = ((currentPageNumber - 1) * posts_per_page);
                end = ((currentPageNumber - 1) * posts_per_page) + posts_per_page;
                current_items = items.slice(start, end);
            }

            if (isPageLink) {
                currentPageNumber = self.data('page');
                self.addClass(activeClass);
                self.siblings().removeClass(activeClass);
            }

            if (totalPages > 5) {

                if ((totalPages - currentPageNumber) < 3){
                    pageNumberItems.each(function(i, value) {
                        pageNumberItems.eq(i).html(i + 1).removeClass('disabled');
                    }); 
                    pageNumberItems.eq(totalPages - 1).html('...').addClass('disabled');
                }
                if (currentPageNumber == totalPages) {
                    pageNumberItems.eq(totalPages - 1).html(totalPages).removeClass('disabled');
                    pageNumberItems.eq(totalPages - 4).html('...').addClass('disabled');
                }

                if ((currentPageNumber < 4)) {
                    pageNumberItems.eq(totalPages - 1).html(totalPages).removeClass('disabled');
                    pageNumberItems.eq(totalPages - 2).html('...').addClass('disabled');
                }
            }

            items.hide();
            for (var i = 0; i < current_items.length; i++) {
                jQuery(current_items[i]).show();
            }
            if (itemsContainer.length > 0) {
                $('body,html').animate({
                    scrollTop: itemsContainer.offset().top + -100
                }, 800);
            }
    });
}

function toggleVideoSound(event) {

    var 
      video=document.getElementById("myVideo"),
      self = event.target,
      toggleText = self.getAttribute('data-toggle-text'),
      currentBtnText = self.innerHTML
    ;
    
    if(video.muted){
        video.muted = false;
        self.innerHTML = toggleText;
        self.setAttribute('data-toggle-text', currentBtnText)
    
    } else {
        video.muted = true;
        self.setAttribute('data-toggle-text', currentBtnText)
        self.innerHTML = toggleText;
    }
}

jQuery(document).on('click', '.modal .view-form', function(){
    var form = jQuery('#custom-enquiry-form form');
    form.find('input, textarea, .select-box, .opt-in-error').addClass('error');
    
})

$(document).ready(function() {
    //locale banner
    var localeBannerCookie = getCookie('locale_banner');
    var isRegionHomepage = window.location.pathname === '/';

    $('#welcomeRegionNoticeModal').on('show.bs.modal', function() {
        setTimeout(function() {
            $('body').removeClass('modal-open');
        },100)
    });

    //align the FE where response was cached
    if(locale) {
        setCookie('language', locale, 365);
    }

    if (!localeBannerCookie && !isRegionHomepage) {
        $.ajax({
            url: '/' + locale + '/products/localebanner/config/index',
            method: "GET",
            success: function (res) {
                if (res && res.enabled && (res.language_ip || res.debug)) {
                    if (res.language !== res.language_ip && res.message || res.debug) {
                        var translatedLocale = translated_locales[locale];
                        
                        $("#welcomeRegionNoticeModal .selected-region").text(translatedLocale);

                        $("#welcomeRegionNoticeModal").modal('show');

                        $('#welcomeRegionNoticeModal button').on('click', function (e) {
                            setCookie('locale_banner', 1, 1);
                        });
                        $('#welcomeRegionNoticeModal .welcome-link').on('click', function (e) {
                            e.preventDefault();
                            setCookie('locale_banner', 1, 1);
                            setCookie('language', "", 0);
                            window.location.href=$(this).attr('href');
                        });
                    }
                }
            },
            dataType: 'json'
        });
    }
});

// todo turn backtocontents into inline callback
if($('.table_of_contents').length > 0) {
    var target = $('.table_of_contents').get(0);
    
    var backToTopBtn = $('.back_to_contents');

    function backToContents(entries) {
        var isIntersecting = entries[0].isIntersecting;
        if (isIntersecting) {
            backToTopBtn.removeClass("d-none");
        }
        
        if (!entries[0].boundingClientRect.top > 0) {
            backToTopBtn.addClass("d-none");
        } 
    }

    var observer = new IntersectionObserver(backToContents, {
        root: null,
        rootMargin: "0px",
        threshold:  "1.0"
    });

    observer.observe(target);

    function scrollToContents() {
        target.scrollIntoView({
            behaviour:'smooth'
        });
    }
}
    
// Hubspot locale tracker

document.cookie = 'current_locale=' + locale + '; domain=bulgin.com; expires=86400000;path=/';
