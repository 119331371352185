var SelectPowerContactType = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'contact_type'
    this.title = 'Select contact type'
    this.modelName = 'connector_one'
}

SelectPowerContactType .prototype = Object.create(CableBuilderStep.prototype)

SelectPowerContactType.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'cable_function',
            value: customCableData[this.modelName].cable_function
        }
    ]
}
