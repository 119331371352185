var SelectPowerConnector = function($container, search, next, modelName) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'sku'
    this.title = 'Select power connector'
    this.modelName = modelName
}

SelectPowerConnector.prototype = Object.create(CableBuilderStep.prototype)

SelectPowerConnector.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'cable_function',
            value: customCableData[this.modelName].cable_function
        },
        {
            field: 'contact_type',
            value: customCableData[this.modelName].contact_type
        },
        {
            field: 'number_of_contacts',
            value: customCableData[this.modelName].number_of_contacts
        },
        {
            field: 'current_max',
            value: customCableData[this.modelName].current_max
        },
        {
            field: 'voltage_max',
            value: customCableData[this.modelName].voltage_max
        },
        {
            field: 'body_material_type',
            value: customCableData[this.modelName].body_material_type
        },
        {
            field: 'min_cable_entry_size',
            value: customCableData.cable.diameter,
            condition_type: 'lte'
        },
        {
            field: 'max_cable_entry_size',
            value: customCableData.cable.diameter,
            condition_type: 'gte'
        }
    ]
}

SelectPowerConnector.prototype.getSearchFields = function() {
    return [
        {
            value: 'sku'
        },
        {
            value: 'product_configurator_image'
        },
    ]
}

SelectPowerConnector.prototype.renderOptions = function(response) {
    this.response = response

    CableBuilderStep.prototype.renderOptions.call(this, response)
}

SelectPowerConnector.prototype.saveAndDisableSelect = function() {
    CableBuilderStep.prototype.saveAndDisableSelect.call(this)

    for (var i = 0; i < this.response.items.length; i++) {
        if (customCableData[this.modelName].sku == this.response.items[i].sku) {
            customCableData[this.modelName].product_configurator_image = this.response.items[i].product_configurator_image
        }
    }

    this.$container.trigger('connector-selected')
}

SelectPowerConnector.prototype.shouldShow = function(hasManyItems) {
    return false
}

SelectPowerConnector.prototype.shouldSelectFirstOption = function(hasManyItems) {
    return true
}
