var showOnMapModule = function () {
    
    var self = this;
    
    /**
     * A distributor has been selected
     */
    self.distributorSelected = function () {
        EventBus.publish('distributor-map-distributor-selected', jQuery(this).data('distributor-id'));
    };
    
    /**
     * Bind events
     */
    self.bindEvents = function () {
        jQuery(document).on('click', '.show-on-map', self.distributorSelected);
    };
    
    self.bindEvents();
    
};

EventBus.subscribe('init-modules', function () {
    new showOnMapModule();
});