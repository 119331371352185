var GeoLocationModule = function () {
    
    var self = this;
    
    /**
     * Location latitude/longitude coordinates
     * @type {object}
     */
    self.coordinates = null;
    
    /**
     * List of callbacks to run when the location is loaded
     * @type {array}
     */
    self.callbacks = [];
    
    /**
     * Whether the user's location has been loaded
     * @type {boolean}
     */
    self.isLoaded = false;
    
    /**
     * Whether the user's location is being loaded
     * @type {boolean}
     */
    self.isLoading = false;
    
    /**
     * Load location
     * @param callback
     */
    self.loadLocation = function (callback) {
        
        //Run callback and return if geolocation is unsupported
        if (!('geolocation' in navigator)) {
            callback(null);
            return;
        }
        
        //Callback with location if it has already been loaded
        if (self.isLoaded) {
            callback(self.coordinates);
            return;
        }
        
        //Add callback to list of callbacks
        self.callbacks.push(callback);
        
        //Return if the user's location is already being loaded
        if (self.isLoading) {
            return;
        }
        
        //Set that the location is being loaded
        self.isLoading = true;
        
        var watchId = navigator.geolocation.watchPosition(function (position) {
            
            //Set that the location has been loaded
            self.isLoaded = true;
            self.isLoaded = false;
            
            //Store coordinates on this module
            self.coordinates = {
                accuracy: position.coords.accuracy,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            };
            
            //Stop location from being checked again as we only require it once
            navigator.geolocation.clearWatch(watchId);
            
            //Execute callback functions
            self.runCallbacks();
            
        }, self.runCallbacks, {
            enableHighAccuracy: true,
            timeout: 5000
        });
            
    };
    
    /**
     * Execute previously stored callbacks
     */
    self.runCallbacks = function () {
        for (var i = 0; i < self.callbacks.length; i ++) {
            self.callbacks[i](self.coordinates);
        }
    };
    
    /**
     * Bind events
     */
    self.bindEvents = function () {
        EventBus.subscribe('get-user-location', self.loadLocation);
    };
    
    self.bindEvents();
    
};

EventBus.subscribe('init-modules', function () {
    new GeoLocationModule();
});