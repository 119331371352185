var InputQty = function($container, next) {
    CableBuilderInputStep.call(this, $container, next)

    this.optionName = 'qty'
    this.title = 'Enter order quantity'
    this.modelName = 'additional'
}

InputQty.prototype = Object.create(CableBuilderInputStep.prototype)

