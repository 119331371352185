var SelectFibreCable = function($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'sku'
    this.title = 'Select cable'
    this.modelName = 'cable'
}

SelectFibreCable.prototype = Object.create(CableBuilderStep.prototype)

SelectFibreCable.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: 'Cables'
        },
        {
            field: 'fibre_mode',
            value: customCableData.connector_one.fibre_mode
        },
        {
            field: 'sheath_material',
            value: customCableData.cable.sheath_material
        },               
        {
            field: 'sheath_colour',
            value: customCableData.cable.sheath_colour
        }                
    ]
}

SelectFibreCable.prototype.getSearchFields = function() {
    return [
        {
            value: 'sku'
        },
        {
            value: 'y_cable_configurator_image'
        },
        {
            value: 'straight_cable_configurator_image'
        }    
    ]
}

SelectFibreCable.prototype.renderOptions = function(response) {
    this.response = response

    CableBuilderStep.prototype.renderOptions.call(this, response)
}

SelectFibreCable.prototype.saveAndDisableSelect = function() {
    CableBuilderStep.prototype.saveAndDisableSelect.call(this)

    for (var i = 0; i < this.response.items.length; i++) {
        if (customCableData.cable.sku == this.response.items[i].sku) {
            customCableData.cable.y_cable_configurator_image = this.response.items[i].y_cable_configurator_image
            customCableData.cable.straight_cable_configurator_image = this.response.items[i].straight_cable_configurator_image
        }
    }

    this.$container.trigger('cable-selected')
}

SelectFibreCable.prototype.shouldShow = function(hasManyItems) {
    return false
}

SelectFibreCable.prototype.shouldSelectFirstOption = function(hasManyItems) {
    return true
}
