var CustomCableEnquiryForm = function($el) {
    this.$el = $el
    this.$parent = this.$el.parent()
    this.$formContainer = this.$el.find('.enquiry-form-container')
    this.$summaryContainer = this.$el.find('.summary-container')
    this.$mainContainer = this.$el.find('.main-container')
    this.$thankYouContainer = this.$el.find('.thank-you-container')
    this.$form = null
    this.lang = this.$el.data('lang')
    this.customerEmailData = {email_criteria: {}}
    this.customerEmailEndpoint = '/' + this.lang + '/products/rest/V1/custom-cable-builder/email'
    this.langFormIdMap = {
        "en": "e170cbce-54bc-48dc-8b99-40708ab9c4a2",
        "us": "e170cbce-54bc-48dc-8b99-40708ab9c4a2",
        "au": "e170cbce-54bc-48dc-8b99-40708ab9c4a2",
        "ca-fr": "1d496c7b-f1ed-4ba5-8d28-9760fa00c80e",
        "fr": "1d496c7b-f1ed-4ba5-8d28-9760fa00c80e",
        "it": "06bd78d4-31d6-44bd-89a8-bea04a44e2a5",
        "de": "ffc4137f-f38f-4397-9d28-dc4e16f44338",
        "es": "5f5cb79a-1876-4098-b52e-82957bf363e9",
        "zh-hans": "32bd29e6-ecf6-4b65-930d-8506e45223c2",
        "ja": "1ea44155-29af-4b7d-bca7-1d90704c9add"
    };
    this.portalId = '4508196'

    this.bindEvents()
}

CustomCableEnquiryForm.prototype.getFormId = function() {
    return this.langFormIdMap[this.lang]
}

CustomCableEnquiryForm.prototype.bindEvents = function() {
    this.$parent.on('cable-built', this.setCustomerEmailData.bind(this))
    this.$parent.on('cable-built', this.setSummary.bind(this))
    this.$parent.on('cable-built', this.setHiddenFormFields.bind(this))
    $.getScript('https://js.hsforms.net/forms/v2.js', this.initForm.bind(this));
    $.getScript('https://cdn2.hubspot.net/hubfs/4508196/assets/form-script-2020.js', this.initForm.bind(this));
}

CustomCableEnquiryForm.prototype.completeEnquiry = function() {
    this.sendCustomerEmail(
        this.showThankYou()
    )
}

CustomCableEnquiryForm.prototype.initForm = function() {
    hbspt.forms.create({
        portalId: this.portalId,
        formId: this.getFormId(),
        target: '#' + this.$formContainer.attr('id'),
        onFormReady: this.setForm.bind(this),
        onFormSubmitted: this.completeEnquiry.bind(this),
    });
}

CustomCableEnquiryForm.prototype.propertyToLabel = function(property) {
    return property.replace(/_/g, ' ')
}

CustomCableEnquiryForm.prototype.isPropertyAllowed = function(property) {
    return property.indexOf('image') === -1
}

CustomCableEnquiryForm.prototype.objectToValueArray = function(prefix, obj) {
    values = []

    for (var prop in obj) {
        if (this.isPropertyAllowed(prop)) {
            values.push(prefix + this.propertyToLabel(prop) + '=' + obj[prop])
        }
    }

    return values
}

CustomCableEnquiryForm.prototype.setForm = function($form) {
    this.$form = $form
}

CustomCableEnquiryForm.prototype.setCustomerEmailData = function(event, cable) {
    this.customerEmailData.email_criteria = {
        qty: cable.selected_data.additional.qty,
        length: cable.selected_data.cable.length,
        cable: cable.selected_data.cable.sku,
        connector1: cable.selected_data.connector_one.sku,
        connector2: typeof(cable.selected_data.connector_two.sku) !== 'undefined' ? cable.selected_data.connector_two.sku : null,
        connector3: typeof(cable.selected_data.connector_three.sku) !== 'undefined' ? cable.selected_data.connector_three.sku : null,
    }
}

CustomCableEnquiryForm.prototype.sendCustomerEmail = function(done) {
    this.customerEmailData.email_criteria.email = this.$form.find('input[name=email]').val() 

    $.post({
        url: this.customerEmailEndpoint,
        data: JSON.stringify(this.customerEmailData),
        contentType: 'application/json'
    })
    .always(done) //if fail, fail silently 
}

CustomCableEnquiryForm.prototype.setSummary = function(event, cable) {
    this.$summaryContainer.find('img').attr('src', cable.cable_icon)
    this.$summaryContainer.find('.pdf').attr('href', cable.pdf_link)
    this.$summaryContainer.find('h3').html(cable.title)
    this.$summaryContainer.find('p').html(cable.summary)
    this.$summaryContainer.find('.qty').html(cable.selected_data.additional.qty)
}

CustomCableEnquiryForm.prototype.setHiddenFormFields = function(event, cable) {
    if (this.$form == null) {
        console.log('WARNING: form is not ready to set hidden fields')

        return false
    }

    product = []
    product.push('PDF link=' + cable.pdf_link)
    product.push('Connector family=' + cable.selected_data.connector_family)

    product = product.concat(this.objectToValueArray('Cable ', cable.selected_data.cable))
    product = product.concat(this.objectToValueArray('Connector one ', cable.selected_data.connector_one))
    product = product.concat(this.objectToValueArray('Connector two ', cable.selected_data.connector_two))
    product = product.concat(this.objectToValueArray('Connector three ', cable.selected_data.connector_three))
    product = product.concat(this.objectToValueArray('', cable.selected_data.additional))

    this.$form.append($('<input/>', {
        type: 'hidden',
        name: 'product_interest__c',
        value: product.join(",\r\n")
    }))

    this.$form.append($('<input/>', {
        type: 'hidden',
        name: 'product_interest_qty',
        value: cable.selected_data.additional.qty
    }))

    this.$form.append($('<input/>', {
        type: 'hidden',
        name: 'lead_source_detail__c',
        value: 'Custom Cable Assembly Builder'
    }))

    this.$form.find('select[name="nature_of_enquiry__c"]').val('Sales').change()

}

CustomCableEnquiryForm.prototype.showThankYou = function($form) {
    this.$mainContainer.hide()
    this.$thankYouContainer.fadeIn()
}

$('.enquiry-form').each(function () {
    new CustomCableEnquiryForm($(this))
})
