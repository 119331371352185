/*
    Synchronise height from one element to another
*/

EventBus.subscribe('init-modules', function () {

    /**
     * Synchronise heights of all selectable parents
    */
    var syncHeights = function () {
        jQuery('.sync-height-from-elem').each(
            function () {
                syncHeight(jQuery(this));
            }
        );
    };
    
    /**
     * Synchronise heights of specific element
     * @param elem
    */
    var syncHeight = function (elem) {
        
        var
            minSyncWidth = elem.data('min-sync-width'),
            target = jQuery(elem.data('height-elem'))
        ;
            
        //Cancel if target element could not be selected or if screen size is below specified breakpoint
        if (target.length === 0 || (minSyncWidth && !window.matchMedia('(min-width: ' + minSyncWidth + 'px)').matches)) {
            elem.css('height', '');
            return;
        }
        
        elem.height(target.height());
        
    };
    
    jQuery(window).resize(syncHeights);
    syncHeights();
    setTimeout(syncHeights, 500);
    
});