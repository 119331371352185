EventBus.subscribe('get-distance', function (params) {
    
	var
        radlat1 = Math.PI * params.lat1/180,
        radlat2 = Math.PI * params.lat2/180,
        theta = params.lon1-params.lon2,
        radtheta = Math.PI * theta/180,
        dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    ;
    
	dist = Math.acos(dist) * 180/Math.PI * 60 * 1.1515;
    
    if (params.hasOwnProperty('unit')) {
        
        if (params.unit == 'K') {
            return dist * 1.609344;
        }
        
        if (params.unit == 'N') {
            return dist * 0.8684;
        }
        
    }
    
	params.callback(dist);
    
});