var SelectFibreCableSheathMaterial = function($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'sheath_material'
    this.title = 'Select sheath material'
    this.modelName = 'cable'
}

SelectFibreCableSheathMaterial.prototype = Object.create(CableBuilderStep.prototype)

SelectFibreCableSheathMaterial.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: 'Cables'
        },
        {
            field: 'fibre_mode',
            value: customCableData.connector_one.fibre_mode
        }                
    ]
}
