var SelectPowerCableVoltageMax = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'voltage_max'
    this.title = 'Select max voltage'
    this.modelName = 'cable'
}

SelectPowerCableVoltageMax.prototype = Object.create(CableBuilderStep.prototype)

SelectPowerCableVoltageMax.prototype.getSearchFilters = function() {
    return [
        {
            field: "product_family",
            value: "Cables"
        },
        {
            field: "nominal_cross_sectional_area_mm2",
            value: customCableData.connector_one.min_contact_accomodation_mm2,
            condition_type: "gte"
        },
        {
            field: "nominal_cross_sectional_area_mm2",
            value: customCableData.connector_one.max_contact_accomodation_mm2,
            condition_type: "lte"
        },
        {
            field: "diameter",
            value: customCableData.connector_one.min_cable_entry_size,
            condition_type: "gte"
        },
        {
            field: "diameter",
            value: customCableData.connector_one.max_cable_entry_size,
            condition_type: "lte"
        },
        {
            field: "number_of_contacts",
            value: customCableData.connector_one.number_of_contacts,
        },
        {
            field: "current_max",
            value: customCableData.cable.current_max,
        },
        {
            field: "voltage_max",
            value: customCableData.connector_one.voltage_max,
            condition_type: "gte"
        }
    ]
}
