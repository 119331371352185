var SelectFibreCableSheathColour = function($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'sheath_colour'
    this.title = 'Select sheath colour'
    this.modelName = 'cable'
}

SelectFibreCableSheathColour.prototype = Object.create(CableBuilderStep.prototype)

SelectFibreCableSheathColour.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: 'Cables'
        },
        {
            field: 'fibre_mode',
            value: customCableData.connector_one.fibre_mode
        },
        {
            field: 'sheath_material',
            value: customCableData.cable.sheath_material
        }                
    ]
}
