jQuery(document).ready(function () {


    // Limit the number of files in a FileList.
    $.validator.addMethod('min-file-upload', function( value, element, param ) {
        
        if ( $( element ).attr( "type" ) === "file" ) {
            if ( storedFiles && storedFiles.length == param ) {
                return true;
            }
            return false;
        }
    });

    $.validator.addMethod(
            "validate_file_type",
            function(val,elem, errorMessage) {
                var files = $('#'+elem.id)[0].files;

                for(var i=0;i<files.length;i++){
                    var fname = files[i].name.toLowerCase();
                    var re = /(\.pdf|\.docx|\.doc)$/i;
                    if(!re.exec(fname))
                    {
                        $.validator.messages.custom_method_name = 'Your message1';
                        return false;
                    }
                }
                $.validator.messages.custom_method_name = 'Your message2';
                return true;
            }, "Files must be one of the following formats .pdf, .doc, .docx");

    $.validator.addMethod('must-be-empty', function(value, element) {
        return (value === '');
    }, 'This field must be empty');


    jQuery('form.validate').each(function () {
        
        var 
            currentForm = jQuery(this)
        ;

        currentForm.data('validator', currentForm.validate({
            errorClass: 'error',
            validClass: 'valid',
            errorPlacement: function (error, element) {

                    if (element.not(":visible")) {
                        var 
                            input = element.attr('data-input')
                        ;

                        if (jQuery(input).length > 0) {
                            jQuery(input).addClass('error');
                        }
                        
                    }

                    if (element.hasClass('show-errors')) {
                        
                        var  
                            errorContainer = element.attr('data-error-container'),
                            errorMessage = element.data('error-message')
                        ;

                        if (errorContainer.length == 0) {
                            return;
                        }
                        errorMessage = errorMessage.length > 0 ? errorMessage : error[0].textContent; 
                        
                        jQuery(errorContainer).html('<span>' +  errorMessage  +'</span>').addClass('show');
                    }
            },
            ignore: '',
            invalidHandler: function(e, validator) {
                if( validator.numberOfInvalids() > 0) {
                    jQuery('html, body').animate({ scrollTop: currentForm.closest('section').offset().top}, 500)
                }
            },
            submitHandler: function (form) {
                if ($(form).hasClass('has-captcha')) {
                    
                    grecaptcha.execute(gRecaptchaSiteKey, { action: 'contact' }).then(function (token) {
                      $('[name="recaptcha_response"]').val(token);

                        if (currentForm.attr('action')) {
                        //Carry out default form submit if an action attribute is present
                            form.submit();
                        }
                        else {
                        //Trigger success event on form to be received in module
                            currentForm.trigger('form-success');
                        }

                    })
                } else {
                    if (currentForm.attr('action')) {
                        //Carry out default form submit if an action attribute is present
                        form.submit();
                    }
                    else {
                        //Trigger success event on form to be received in module
                            currentForm.trigger('form-success');
                    }
                }
            }
        }));
    });
});