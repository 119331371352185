var CableBuilderStep = function ($container, search, next) {
    this.$container = $container
    this.next = next
    this.search = search
    this.$select = null
}

CableBuilderStep.prototype.run = function() {
    if (this.$select) {
        this.saveAndDisableSelect()
    }

    this.next.setup()
}

CableBuilderStep.prototype.setup = function() {
    this.getOptions()
}

CableBuilderStep.prototype.getOptions = function() {
    this.search.get(
        this.getSearchFilters(),
        this.getSearchFields(),
        this.renderOptions.bind(this),
        this.renderError.bind(this),
        this.$container
    )
}

CableBuilderStep.prototype.renderOptions = function(response) {
    if (response.items.length < 1) {
        return alert('Your search has yielded zero options. Please reset all filters and start again.')
    }

    var hasManyItems = response.items.length > 1

    this.$select = CableBuilderSelect.make(
    	this.optionName, 
        this.title,
    	response.items, 
    	hasManyItems,
    	this.$container
    )

    this.$select.on('change', this.run.bind(this))

    this.$container.parent().fadeIn()

    if (this.shouldShow(hasManyItems)) {
        this.$select.parent().fadeIn()
    }

    if (this.shouldSelectFirstOption(hasManyItems)) {
        this.selectFirstOption()
    }
}

CableBuilderStep.prototype.saveAndDisableSelect = function() {
    customCableData[this.modelName][this.optionName] = this.$select.val()
    this.$select.prop('disabled', 'disabled');
}

CableBuilderStep.prototype.selectFirstOption = function() {
    this.$select.val(this.$select.find('option[value!=""]:first').val())
    this.$select.trigger('change')
}

CableBuilderStep.prototype.shouldShow = function(hasManyItems) {
    return true
}

CableBuilderStep.prototype.shouldSelectFirstOption = function(hasManyItems) {
    return ! hasManyItems
}

CableBuilderStep.prototype.getSearchFields = function() {
    return [
        {
            value: this.optionName
        }
    ]
}

CableBuilderStep.prototype.renderError = function(XMLHttpRequest, textStatus, errorThrown) {
    alert('Sorry there has been an internal error. Please try again later.')
}
