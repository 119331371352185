var GoogleMapsModule = function () {
    
    var self = this;
    
    /**
     * Whether Google Maps has been loaded
     * @type {boolean}
     */
    self.isLoaded = false;
    
    /**
     * Whether Google Maps is being loaded
     * @type {boolean}
     */
    self.isLoading = false;
    
    /**
     * Callbacks to execute once the Maps API is loaded
     * @type {array}
     */
    self.callbacks = [];
    
    /**
     * @type {object}
     */
    self.libraryHash = {};
    
    /**
     * @type {array}
     */
    self.libraries = [];
    
    /**
     * Load Google Maps
     * @param callback
     */
    self.loadGoogleMaps = function (callback) {
        
        //Callback if Maps API is already loaded
        if (self.isLoaded) {
            callback();
            return;
        }
        
        //Add callback to callbacks list
        self.callbacks.push(callback);
        
        //Cancel if API is being loaded
        if (self.isLoading) {
            return;
        }
        
        //Set that the API is being loaded
        self.isLoading = true;

        var libraries = self.libraries.join(',');

        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + gMapsApiKey + '&libraries=' + libraries + '&loading=async&callback=initMap';
    
        window.initMap = function() {
            self.isLoaded = true;
            self.isLoading = false;
    
            // Execute callbacks now that the API is loaded
            self.callbacks.forEach(function(cb) {
                cb();
            });
        };
    
        // Define an error handler for the script
        script.onerror = function() {
            console.error('Failed to load the Google Maps API');
            self.isLoading = false;
        };
    
        document.head.appendChild(script);
    };
    
    /**
     * Map container initialised
     * @param element
     */
    self.mapContainerInitialised = function (element) {
        element.addClass('initialised');
    };
    
    /**
     * Construct Google map
     * @param params
     */
    self.constructGoogleMap = function (params) {

        var
            mapCenter = new google.maps.LatLng(params.center.latitude, params.center.longitude),
            defaultOptions = {
                mapId:              params.id,
                center:             mapCenter,
                zoom:               10,
                zoomControl:        true,
                streetViewControl:  false,
                mapTypeControl:     false,
                scrollwheel:        false
            },
            mapObject = new google.maps.Map(params.element, jQuery.extend(defaultOptions, params.options))
        ;

        params.callback({
            center: mapCenter,
            map:    mapObject,
        });
        
    };
    
    /**
     * Construct map icon
     * @param params
     */
    self.constructMapIcon = function (params) {
        params.callback({
            url: params.src,
            size: new google.maps.Size(params.width, params.height),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(params.scaledWidth, params.scaledHeight),
            scaledSize: new google.maps.Size(params.scaledWidth, params.scaledHeight)
        });
    };
    
    /**
     * Construct map markers
     * @param params
     */
    self.constructMapMarkers = function (params){
        
        var
            marker,
            markers = {},
            position
        ;

        jQuery.each(params.positions, function (i, coordinate) {
            position = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
            marker = self.createMarkerObject(coordinate.title, position, params.hasOwnProperty('icon') ? params.icon : null, params.map);
            marker.id = coordinate.id;
            markers[coordinate.id] = marker;
        });

        if (typeof params.callback == 'function') {
            params.callback(markers);
        }
    };
    
    /**
     * Return map marker object
     * @param title
     * @param position
     * @param icon
     * @param map
     */
    self.createMarkerObject = function (title, position, icon, map) {

        var 
            iconImg = document.createElement('img')
            iconImg.src = icon.url
            marker = new google.maps.marker.AdvancedMarkerElement({
                title: title,
                position: position,
                content: iconImg,
                map: map
        });
        marker.getPosition = function() {
            return position;
        }

        marker.getMap = function() {
            return map;
        }

        return marker
    };
    
    /**
     * Refresh map
     * @param params
     */
    self.refreshMap = function (params) {
        google.maps.event.trigger(params.map, 'resize');
        self.centerMap(params);
    };
    
    /**
     * Center map
     * @param params
     */
    self.centerMap = function (params) {
        params.map.setCenter(params.center);
    };
    
    /**
     * Zoom in map
     * @param map
     */
    self.zoomInMap = function (map) {
        map.setZoom(map.getZoom() + 1);
    };
    
    /**
     * Zoom out map
     * @param map
     */
    self.zoomOutMap = function (map) {
        map.setZoom(map.getZoom() - 1);
    };
    
    /**
     * Remove map markers
     * @param markers
     */
    self.removeMapMarkers = function (markers) {
        jQuery.each(markers, function (i, marker) {
            marker.setMap(null);
        });
    };
    
    /**
     * Show map markers
     * @param params
     */
    self.showMapMarkers = function (params) {
        jQuery.each(params.markers, function (i, marker) {
            marker.setMap(params.map);
        });
    };
    
    /**
     * Show map markers
     * @param params
     */
    self.bindMarkerClicks = function (params) {
        jQuery.each(params.markers, function (i, marker) {
            google.maps.event.addListener(marker, 'click', params.callback);
        });
    };
    
    /**
     * Update center of the map to new coordinates
     * @param params
     */
    self.updateMapCoord
    
    
    
    inates = function (params) {
        
        //Get coordinates object
        var center = new google.maps.LatLng(params.coordinates.latitude, params.coordinates.longitude);
        
        //Apply coordinates to map
        self.centerMap({
            center: center,
            map: params.map
        });
        
    };
    
    /**
     * Request an additional library be loaded to maps
     * @param library
     */
    self.addLibrary = function (library) {
        if (!self.libraryHash.hasOwnProperty(library)) {
            self.libraries.push(library);
            self.libraryHash[library] = true;
        }
    };
    
    /**
     * Request that Google Places be loaded as a library
     */
    self.loadGooglePlaces = function () {
        self.addLibrary('places');
    };

    /**
     * Request that Google Marker be loaded as a library
     */
    self.loadGoogleMarker = function () {
        self.addLibrary('marker');
    };
    
    
    /**
     * Setup autocomplete input
     * @params
     */
    self.setupAutoComplete = function (params) {
        
        var circle = new google.maps.Circle({
            center: {
                lat: params.coordinates.latitude,
                lng: params.coordinates.longitude
            },
            radius: params.coordinates.accuracy
        });
        
        var autocomplete = new google.maps.places.Autocomplete(params.input, params.options);
        
        autocomplete.bindTo('bounds', params.map);
        autocomplete.addListener('place_changed', params.change);

        params.callback(autocomplete);
        
    };
    
    /**
     * Pan map to location
     * @param params
     */
    self.panMapTo = function (params) {
        params.map.panTo(params.location);
    };
    
    /**
     * Set map zoom
     * @param params
     */
    self.setMapZoom = function (params) {
        params.map.setZoom(params.zoom);
    };

    /**
     * Set map styles
     * @param params
     */
    self.setMapStyles = function(params) {
        params.map.mapTypes.set('styled_map', new google.maps.StyledMapType(params.styles))
        params.map.setMapTypeId('styled-map')
    }
    
    /**
     * Bind events
     */
    self.bindEvents = function () {
        EventBus
            .subscribe('load-google-maps', self.loadGoogleMaps)
            .subscribe('map-container-initialised', self.mapContainerInitialised)
            .subscribe('construct-google-map', self.constructGoogleMap)
            .subscribe('refresh-google-map', self.refreshMap)
            .subscribe('center-google-map', self.centerMap)
            .subscribe('zoom-in-map', self.zoomInMap)
            .subscribe('zoom-out-map', self.zoomOutMap)
            .subscribe('construct-map-icon', self.constructMapIcon)
            .subscribe('construct-map-markers', self.constructMapMarkers)
            .subscribe('remove-map-markers', self.removeMapMarkers)
            .subscribe('show-map-markers', self.showMapMarkers)
            .subscribe('bind-marker-clicks', self.bindMarkerClicks)
            .subscribe('update-map-coordinates', self.updateMapCoordinates)
            .subscribe('load-google-places-library', self.loadGooglePlaces)
            .subscribe('load-google-marker-library', self.loadGoogleMarker)
            .subscribe('setup-map-autocomplete', self.setupAutoComplete)
            .subscribe('pan-map-to', self.panMapTo)
            .subscribe('set-map-zoom', self.setMapZoom)
            .subscribe('set-map-styles', self.setMapStyles)
        ;
    };

    self.bindEvents();
    
};

EventBus.subscribe('init-modules', function () {
    new GoogleMapsModule();
});