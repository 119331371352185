var SelectAddtionalDataBodyMaterialType = function($container, search, next, name) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'body_material_type'
    this.title = 'Select body material'
    this.modelName = name
}

SelectAddtionalDataBodyMaterialType.prototype = Object.create(CableBuilderStep.prototype)

SelectAddtionalDataBodyMaterialType.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'ethernet_category',
            value: customCableData.connector_one.ethernet_category
        }    
    ]
}
