var SelectFibreConnector = function($container, search, next, modelName) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'sku'
    this.title = 'Select fiber connector'
    this.modelName = modelName
}

SelectFibreConnector.prototype = Object.create(CableBuilderStep.prototype)

SelectFibreConnector.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'product_series',
            value: customCableData[this.modelName].product_series
        },
        {
            field: 'cable_function',
            value: customCableData[this.modelName].cable_function
        },
        {
            field: 'fibre_mode',
            value: customCableData.connector_one.fibre_mode
        }    
    ]
}

SelectFibreConnector.prototype.getSearchFields = function() {
    return [
        {
            value: 'sku'
        },
        {
            value: 'product_configurator_image'
        },
    ]
}

SelectFibreConnector.prototype.renderOptions = function(response) {
    this.response = response

    CableBuilderStep.prototype.renderOptions.call(this, response)
}

SelectFibreConnector.prototype.saveAndDisableSelect = function() {
    CableBuilderStep.prototype.saveAndDisableSelect.call(this)

    for (var i = 0; i < this.response.items.length; i++) {
        if (customCableData[this.modelName].sku == this.response.items[i].sku) {
            customCableData[this.modelName].product_configurator_image = this.response.items[i].product_configurator_image
        }
    }

    this.$container.trigger('connector-selected')
}

SelectFibreConnector.prototype.shouldShow = function(hasManyItems) {
    return false
}

SelectFibreConnector.prototype.shouldSelectFirstOption = function(hasManyItems) {
    return true
}
