var SelectDataCableDiameter = function($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'diameter'
    this.title = 'Select diameter'
    this.modelName = 'cable'
}

SelectDataCableDiameter.prototype = Object.create(CableBuilderStep.prototype)

SelectDataCableDiameter.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: 'Cables'
        },
        {
            field: 'ethernet_category',
            value: customCableData.connector_one.ethernet_category
        },
        {
            field: 'diameter',
            condition_type: 'gte',
            value: customCableData.connector_one.min_cable_entry_size
        },
        {
            field: 'diameter',
            condition_type: 'lte',
            value: customCableData.connector_one.max_cable_entry_size
        }
    ]
}
