var SelectDataConnector = function($container, search, next, modelName) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'sku'
    this.title = 'Select data connector'
    this.modelName = modelName
}

SelectDataConnector.prototype = Object.create(CableBuilderStep.prototype)

SelectDataConnector.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'ethernet_category',
            value: customCableData.connector_one.ethernet_category
        },
        {
            field: 'body_material_type',
            value: customCableData[this.modelName].body_material_type
        },
        {
            field: 'min_cable_entry_size',
            value: customCableData.cable.diameter,
            condition_type: 'lte'
        },
        {
            field: 'max_cable_entry_size',
            value: customCableData.cable.diameter,
            condition_type: 'gte'
        }
    ]
}

SelectDataConnector.prototype.getSearchFields = function() {
    return [
        {
            value: 'sku'
        },
        {
            value: 'product_configurator_image'
        },
    ]
}

SelectDataConnector.prototype.renderOptions = function(response) {
    this.response = response

    CableBuilderStep.prototype.renderOptions.call(this, response)
}

SelectDataConnector.prototype.saveAndDisableSelect = function() {
    CableBuilderStep.prototype.saveAndDisableSelect.call(this)

    for (var i = 0; i < this.response.items.length; i++) {
        if (customCableData[this.modelName].sku == this.response.items[i].sku) {
            customCableData[this.modelName].product_configurator_image = this.response.items[i].product_configurator_image
        }
    }

    this.$container.trigger('connector-selected')
}

SelectDataConnector.prototype.shouldShow = function(hasManyItems) {
    return false
}

SelectDataConnector.prototype.shouldSelectFirstOption = function(hasManyItems) {
    return true
}
