jQuery(document).on('form-success', '.form-ajax', function () {
    var form = jQuery(this);
    
    if (form.data('submitted')) {
        return;
    }
    
    form.data('submitted', true);

    var 
        formData = new FormData(form[0]),
        submitContainer = jQuery('#submit-container'),
        submitting_animation = jQuery('#submitting')

    ;
    
    formData.append('action', form.data('action'));

    form.find('fieldset').prop('disabled', true);

    if (submitContainer.length > 0) {
        submitContainer.find('input').hide();
    }

    if (submitting_animation.length > 0) {
        submitting_animation.show();
    }
    
    jQuery.ajax({
        url: ajaxUrl,
        type: 'POST',
        data: formData,
        contentType: false,
        processData: false,
        success: function(response) {
            if (response.success == false) {

                    var errors = {};
                for (var i = 0; i < response.data.errors.length; i++) {
                    errors[response.data.errors[i]] = true;
                }
                jQuery.each(errors, function(){
                    jQuery('.error-container').append(errors);
                });
                form.data('submitted', false);
                form.find('fieldset').prop('disabled', false);
                return;
            }
            
            //Handle success
            if (response.success && form.data('show')) {
                form.hide();
                jQuery(form.data('show')).fadeIn(); 
            }
        },
        error: function(response) {
            console.log(response);
            if (submitContainer.length > 0) {
                submitContainer.find('input').hide();
            }

            if (submitting_animation.length > 0) {
                submitting_animation.show();
            }
        }
    });
});