var SelectAddtionalPowerContactType = function($container, search, next, modelName) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'contact_type'
    this.title = 'Select contact type'
    this.modelName = modelName
}

SelectAddtionalPowerContactType.prototype = Object.create(CableBuilderStep.prototype)

SelectAddtionalPowerContactType.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'cable_function',
            value: customCableData[this.modelName].cable_function
        },
        {
            field: 'number_of_contacts',
            value: customCableData.connector_one.number_of_contacts
        },
        {
            field: 'current_max',
            value: customCableData.connector_one.current_max
        },
        {
            field: 'voltage_max',
            value: customCableData.connector_one.voltage_max
        },
        {
            field: 'min_cable_entry_size',
            value: customCableData.cable.diameter,
            condition_type: 'lte'
        },
        {
            field: 'max_cable_entry_size',
            value: customCableData.cable.diameter,
            condition_type: 'gte'
        }
    ]
}
