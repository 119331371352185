var SelectDataEthernetCategory = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'ethernet_category'
    this.title = 'Select ethernet category'
    this.modelName = 'connector_one'
}

SelectDataEthernetCategory.prototype = Object.create(CableBuilderStep.prototype)

SelectDataEthernetCategory.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        }
    ]
}
