var StorageModule = function () {
    
    var self = this;
    
    /**
     * Initialize module
     */
    self.init = function () {
        self.isSupported = self.checkSupport();
        self.bindEvents();
    };
    
    /**
     * Return whether local storage is supported
     */
    self.checkSupport = function () {
        
        var mod = 'test';
        
        try {
            localStorage.setItem(mod, mod);
            localStorage.removeItem(mod);
            return true;
        }
        catch (e) {
            return false;
        }
        
    };
    
    /**
     * Write to storage
     */
    self.write = function (params) {
        if (self.isSupported) {
            localStorage.setItem(params.key, params.value);
        }
    };
    
    /**
     * Read from storage
     * @param params
     */
    self.read = function (params) {
        params.callback(self.isSupported ? localStorage.getItem(params.key) : null);
    };
    
    /**
     * Clear from storage
     * @param key
     */
    self.clear = function (key) {
        if (self.isSupported) {
            localStorage.removeItem(key);
        }
    };
    
    /**
     * Bind events
     */
    self.bindEvents = function () {
        
        EventBus
            .subscribe('storage.read', self.read)
            .subscribe('storage.write', self.write)
            .subscribe('storage.clear', self.clear)
        ;
        
    };
    
    self.init();
    
};

EventBus.subscribe('init-modules', function () {
    new StorageModule();
});