var InputCableLength = function($container, next) {
    CableBuilderInputStep.call(this, $container, next)

    this.optionName = 'length'
    this.title = 'Enter cable length (mm)'
    this.modelName = 'cable'
}

InputCableLength.prototype = Object.create(CableBuilderInputStep.prototype)

InputCableLength.prototype.saveAndDisableInput = function() {
    customCableData[this.modelName][this.optionName] = this.$input.val() + 'mm'
    this.$input.prop('disabled', 'disabled');
}
