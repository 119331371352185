var SelectPowerCable = function($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'sku'
    this.title = 'Select cable'
    this.modelName = 'cable'
}

SelectPowerCable.prototype = Object.create(CableBuilderStep.prototype)

SelectPowerCable.prototype.getSearchFilters = function() {
    return [
        {
            field: "nominal_cross_sectional_area_mm2",
            value: customCableData.connector_one.min_contact_accomodation_mm2,
            condition_type: "gte"
        },
        {
            field: "nominal_cross_sectional_area_mm2",
            value: customCableData.connector_one.max_contact_accomodation_mm2,
            condition_type: "lte"
        },
        {
            field: "diameter",
            value: customCableData.connector_one.min_cable_entry_size,
            condition_type: "gte"
        },
        {
            field: "diameter",
            value: customCableData.connector_one.max_cable_entry_size,
            condition_type: "lte"
        },
        {
            field: "number_of_contacts",
            value: customCableData.connector_one.number_of_contacts,
        },
        {
            field: "current_max",
            value: customCableData.cable.current_max,
        },
        {
            field: "voltage_max",
            value: customCableData.cable.voltage_max,
        },
        {
            field: 'sheath_material',
            value: customCableData.cable.sheath_material
        },
        {
            field: 'sheath_colour',
            value: customCableData.cable.sheath_colour
        }                
    ]
}

SelectPowerCable.prototype.getSearchFields = function() {
    return [
        {
            value: 'sku'
        },
        {
            value: 'y_cable_configurator_image'
        },
        {
            value: 'straight_cable_configurator_image'
        },
        {
            value: 'diameter'
        },
    ]
}

SelectPowerCable.prototype.renderOptions = function(response) {
    this.response = response

    CableBuilderStep.prototype.renderOptions.call(this, response)
}

SelectPowerCable.prototype.saveAndDisableSelect = function() {
    CableBuilderStep.prototype.saveAndDisableSelect.call(this)

    for (var i = 0; i < this.response.items.length; i++) {
        if (customCableData.cable.sku == this.response.items[i].sku) {
            customCableData.cable.y_cable_configurator_image = this.response.items[i].y_cable_configurator_image
            customCableData.cable.straight_cable_configurator_image = this.response.items[i].straight_cable_configurator_image
            customCableData.cable.diameter = this.response.items[i].diameter
        }
    }

    this.$container.trigger('cable-selected')
}

SelectPowerCable.prototype.shouldShow = function(hasManyItems) {
    return false
}

SelectPowerCable.prototype.shouldSelectFirstOption = function(hasManyItems) {
    return true
}
