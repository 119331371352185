var captureDistributorLink = function(url) {
    gtag('event', 'click', {
        'event_category': 'distributor-link',
        'event_label': url,
        'transport_type': 'beacon',
        'event_callback': function() {
            window.open(url, '_blank');
        }
    });
}
