var SetMinMaxPowerContactAccomodation = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.modelName = 'connector_one'
}

SetMinMaxPowerContactAccomodation.prototype = Object.create(CableBuilderStep.prototype)

SetMinMaxPowerContactAccomodation.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'cable_function',
            value: customCableData[this.modelName].cable_function
        },
        {
            field: 'contact_type',
            value: customCableData[this.modelName].contact_type
        },
        {
            field: 'number_of_contacts',
            value: customCableData[this.modelName].number_of_contacts
        },
        {
            field: 'current_max',
            value: customCableData[this.modelName].current_max
        },
        {
            field: 'voltage_max',
            value: customCableData[this.modelName].voltage_max
        },
        {
            field: 'body_material_type',
            value: customCableData[this.modelName].body_material_type
        }
    ]
}

SetMinMaxPowerContactAccomodation.prototype.getSearchFields = function() {
    return [
        {
            value: 'min_contact_accomodation_mm2'
        },
        {
            value: 'max_contact_accomodation_mm2'
        },
        {
            value: 'min_cable_entry_size'
        },
        {
            value: 'max_cable_entry_size'
        }                
    ]
}

SetMinMaxPowerContactAccomodation.prototype.renderOptions = function(response) {
    if (response.items.length < 1) {
        return alert('Your search has yielded zero options. Please reset all filters and start again.')
    }

    var minContactValues = response.items.map(function(x) {
        return parseFloat(x.min_contact_accomodation_mm2)
    })
    
    var maxContactValues = response.items.map(function(x) {
        return parseFloat(x.max_contact_accomodation_mm2)
    })

    var minEntryValues = response.items.map(function(x) {
        return parseFloat(x.min_cable_entry_size)
    })
    
    var maxEntryValues = response.items.map(function(x) {
        return parseFloat(x.max_cable_entry_size)
    })

    customCableData[this.modelName].min_contact_accomodation_mm2 = Math.min.apply(Math, minContactValues)
    customCableData[this.modelName].max_contact_accomodation_mm2 = Math.max.apply(Math, maxContactValues)

    customCableData[this.modelName].min_cable_entry_size = Math.min.apply(Math, minEntryValues)
    customCableData[this.modelName].max_cable_entry_size = Math.max.apply(Math, maxEntryValues)

    this.run()
}
