var DistributorMapModule = function () {

    var self = this;

    /**
     * @var array
     */
    self.container = jQuery('#distributors-map-container');
    if (self.container.length === 0) {
        return;
    }

    /**
     * @var array
     */
    self.mapElement = self.container.children('div');
    if (self.mapElement.length === 0) {
        return;
    }

    /**
     * @var object
     */
    self.map = null;

    /**
     * @var object
     */
    self.mapCenter = {};


    /**
     * The current unix time
     * @var integer
     */
    self.timestamp = 0;
    
    /**
     * The key used for local storage of location
     * @var string
     */
    self.locationKey = 'user-location';

    /**
     * Whether the location map has been constructed
     * @var boolean
     */
    self.isLoaded = false;

    /**
     * Whether the location map has distributor filters
     * @var boolean
     */
    self.hasFilters = false;

    /**
     * My Location marker icon
     * @type {object}
     */
    self.distributorIcon = {};


    /**
     * @var object
     */
    self.coordinates = {
        accuracy: 0,
        latitude: 51.512777,
        longitude: -0.142183
    };

    /**
     * @var boolean
     */
    self.hasUserLocation = false;

    /**
     *
     * @var object
     */
     self.bounds = null;


    /**
     * @var array
     */
    self.markers = [];
   
    /**
     *
     * @var object
     */
    self.infoWindow = null;

    /*
     *
     * @var boolean
    */
    self.isCentered = true;

    self.nearestMarker = null;


    /**
     * Load Google API
     */
    self.loadGoogleApi = function () {
        EventBus.publish('load-google-api', self.googleApiLoaded);
    };

    /**
     * All modules have been initialised
     */
    self.modulesInitialised = function () {
        EventBus.publish('load-google-places-library');
        EventBus.publish('load-google-marker-library');

        self.setTimestamp();
        self.loadUserLocation();

        if (jQuery('.product-filter-bar').length > 0) {
          self.hasFilters = true;
        }
        
    };

    /**
     * Load user location
     */
    self.loadUserLocation = function () {
        
        //Attempt to load location from local storage
        EventBus.publish('storage.read', {
            callback: self.handleLocationStorage,
            key: self.locationKey
        });
        
    };

    /**
     * User locations has been loaded
     * @param coordinates
     */
    self.userLocationLoaded = function (coordinates) {
        
        //Cancel if no coordinates were loaded
        if (!coordinates) {
            return;
        }
        
        //Store new coordinates data in local storage
        EventBus.publish('storage.write', {
            key: self.locationKey,
            value: JSON.stringify({
                coordinates: coordinates,
                created: self.timestamp
            })
        });
        
        //Update coordinates for the map
        self.updateCoordinates(coordinates);
        
    };


    /**
     * Update the coordinates for the map
     * @param coordinates
     */
    self.updateCoordinates = function (coordinates) {

        //Store coordinates
        self.coordinates = coordinates;
        
        //Set that the user has a known location
        self.hasUserLocation = true;
        
        //Update Map if it has been constructed
        if (self.isLoaded) {
            
            //Send new coordinates to the map
            EventBus.publish('update-map-coordinates', {
                coordinates: coordinates,
                map: self.map
            });
            
            //Display nearest distributor
            self.showNearestDistributor(coordinates);
            
        }
      
    };


    /**
     * Google API has been loaded
     */
    self.googleApiLoaded = function () {
        EventBus.publish('load-google-maps', self.googleMapsLoaded);
    };


     /**
     * Google Maps has been loaded
     */
    self.googleMapsLoaded = function () {

        EventBus.publish('construct-google-map', {
            id: 'distributorsMap',
            center: self.coordinates,
            callback: self.mapConstructed,
            element: self.mapElement[0],
            options: {
                zoom: self.container.data('zoom'),
                minZoom: 5,
                maxZoom: 13
            }
        });
    };

    /**
     * Map element has been constructed
     * @param data
     */
    self.mapConstructed = function (data) {

        //Set that the map has been constructed
        self.isLoaded = true;

        self.mapCenter = data.center;
        self.map = data.map;

        //Setup distributor icon
        self.setupDistributorIcon();

        // setup map styles
        self.setupStyledMapType();

        //Setup markers
        self.setupMarkers();
        self.setupMarkerClusters();
        

        //Show nearest distributor if the user has a known location
        if (self.hasUserLocation) {
            self.showNearestDistributor(self.coordinates);
        }

    };


    /**
     * Set unix timestamp
     */
    self.setTimestamp = function () {
        var date = new Date();
        self.timestamp = Math.floor(date / 1000);
    };

    /**
     * Handle location storage
     * @param value
     */
    self.handleLocationStorage = function (value) {
        
        var data = JSON.parse(value);
        
        //Use stored coordinates data if it exists and is from the last 24 hours
        if (data && data.created > self.timestamp - (60 * 60 * 24)) {
            //Update coordinates for the map
            self.updateCoordinates(data.coordinates);
            return;
        }
        
        //Use geolocation to obtain new user location
        EventBus.publish('get-user-location', self.userLocationLoaded);
        
    };
    

    /**
     * Setup distributor icon
     */
    self.setupDistributorIcon = function () {
        EventBus.publish('construct-map-icon', {
            callback:       self.setDistributorIcon,
            src:            themeUrl + '/assets/img/white-pin.svg',
            width:          24,
            height:         38,
            scaledWidth:    24,
            scaledHeight:   38
        });
    };

    /**
     * Set distributor icon
     * @param icon
     */
    self.setDistributorIcon = function (icon) {
        self.distributorIcon = icon;
    };


    /**
     * Setup distributor markers
     */
    self.setupMarkers = function () {

        //Setup marker parameters
        var params = {
            icon: self.distributorIcon,
            map: self.map,
            positions: [],
            callback: self.distributorMarkersConstructed
        };

        //Store distributors in to marker positions
        jQuery.each(distributorData, function (id, distributor) {

            if (!distributor){
                return;
            }

            //Cancel if contact has no coordinates
            if (!distributor.hasOwnProperty('coordinates') || distributor.coordinates.latitude == "" || distributor.coordinates.longitude == "" ) {
                return;
            }

            params.positions.push({
                id: id,
                latitude: distributor.coordinates.latitude,
                longitude: distributor.coordinates.longitude,
                title: distributor.company
            });

        });


        //Call to construct markers
        EventBus.publish('construct-map-markers', params);


    };


    self.setupMarkerClusters = function() {
        var 
            markerCluster = new MarkerClusterer(self.map, self.markers, {
                imagePath: themeUrl + '/assets/img/m',
                maxZoom: 8
            })
        ;
    };

    /**
     * Setup distributor markers
     */
    self.setInfowindow = function (id) {

        var 
            infoWindow = new google.maps.InfoWindow(),
            distributor = distributorData[id],
            marker = self.markers[id]
        ;

          // Wrap the content inside an HTML DIV in order to set height and width of InfoWindow.
                    
                    infoWindow.setContent(
                      "<div class='info-window container px-0'>"  + 
                          "<div class='row text-center'>" +

                                 "<div class='col px-0 d-block d-lg-none'>" +
                                            "<img width='100' height='100' class='img-fluid distributor-logo' src='" + 
                                                distributor.logo +
                                            "'/>" +
                                    "</div>" +
   
                           "</div>" +

                          "<div class='row'>" +

                                    "<div class='col-lg-3 pl-0 d-none d-lg-block'>" +
                                            "<img width='100' class='img-fluid distributor-logo' src='" + 
                                                distributor.logo +
                                            "'/>" +
                                    "</div>" +

                                    "<div class='col col-xl-9'>" +
                                    "<div class='col px-0 pt-0 pt-lg-4'>" +
                                        "<p class='font-weight-bold'>" + 
                                            distributor.company +  
                                        "</p>" +
                                    "</div>" +

                              "<div class='row'>" + 
                                
                                "<div class='col-4'>" + 
                                    "<p class='font-weight-bold'>Office Address</p>" + 
                                "</div>" +
                                
                                "<div class='col-8'>"+ 
                                    "<p class='font-weight-light'>" + 
                                        distributor.address + 
                                    "</p>" +
                                    ((distributor.website.indexOf('mailto:') > -1) ? ('') : ("<p><a target='_blank' href='" + distributor.website +"' onclick=\"captureDistributorLink('" + distributor.website + "'); return false;\">" + distributor.website)) + 
                                    "</a></p>" + 
                                "</div>" +

                            "</div>" +

                            "<div class='row'>" + 
                            
                                "<div class='col-4'>" + 
                                  "<p class='font-weight-bold'>" + (distributor.telephone.length > 0 ? "Phone Number" : "") + "</p>" +
                                "</div>" +
                                
                                "<div class='col-8'>" + 
                                  "<a class='font-weight-light' href='tel:" + distributor.telephone +"'>" + distributor.telephone + "</a>" + 
                                "</div>" + 

                            "</div>" +

                            "<div class='row'>" + 
                                
                                "<div class='col-4'>" + 
                                  "<p class='font-weight-bold'>" + (distributor.email.length > 0 ? "Email Address" : "") + "</p>" + 
                                "</div>" +
                                
                                "<div class='col-8'>" + 
                                    "<p class='font-weight-normal'>" + 
                                        "<a href='mailto:"+ distributor.email +"'>"+ distributor.email + "</a>" + 
                                    "</p>" + 
                                "</div>" + 

                            "</div>" + 
                              
                              "<div class='row'>" + 
                                  "<div class='col-8 offset-4'>" +
                                      "<p class='font-weight-normal'>" + 
                                           distributor.type +
                                      "</p>" +
                                  "</div>" +
                        "</div>" + 

                      "</div>" +
                      "</div>" +

              "</div>");

                if (self.infoWindow) self.infoWindow.close();
                    infoWindow.open(self.map, marker);
                    self.infoWindow = infoWindow;

            google.maps.event.addListener(self.map, "dragstart", function (e) {
                if ( jQuery('html').hasClass('no-touch') ) {
                    infoWindow.close(self.map, marker);
                }
            });
    };

    /**
     * Distributor markers constructed
     * @param markers
     */
    self.distributorMarkersConstructed = function (markers) {


        EventBus.publish('bind-marker-clicks', {
            callback: self.distributorMarkerClicked,
            markers: markers
        });

        self.markers = markers;

    };

    /**
     * A distributor marker has been clicked
     */
    self.distributorMarkerClicked = function () {
        EventBus.publish('scroll-to-target', {
            target: self.container,
            bottom: 0
        });
        self.isCentered = true;
        self.updateMapDistributor(this);
    };


    /**
    * Distributor Filtered
    */
    self.distributorsFiltered = function (id) {
    var 
        id = isNaN(id) ? 0 : id
    ;
    
        self.isCentered = true;
        self.updateMapDistributor(self.markers[id]);
    };

    /**
     * A distributor marker has been clicked
     */
    self.distributorSelected = function (id) {
        EventBus.publish('scroll-to-target', {
            target: self.container,
            bottom: 0
        });
        var 
            id = isNaN(id) ? 0 : id
        ;
        
        self.isCentered = true;
        self.updateMapDistributor(self.markers[id]);
    };


    /**
     * Update map to show Distributor marker
     * @param marker
     */
    self.updateMapDistributor = function (marker, isResizing) {

        var
            bounds = new google.maps.LatLngBounds(),
            isResizing = typeof isResizing  !== 'undefined' ? isResizing : false
        ;

            bounds.extend(marker.position);
            self.map.fitBounds(bounds);


        //Pan map to markers coordinates
        EventBus.publish('pan-map-to', {
            location: marker.position,
            map: self.map
        });

        if (window.innerWidth < 1199 && jQuery('html').hasClass('no-touch')) {

            window.addEventListener('resize',  function(){
               if (self.infoWindow) self.infoWindow.close();
            });

        } 

        self.updateMapPan(marker);

        if (self.hasFilters) {
            self.map.panBy(-13, -320);
        }

        if (!self.hasFilters && window.innerWidth < 1199) {
          self.map.panBy(0, -310);
        
        } 
        if (window.innerWidth > 1299 && isResizing) {
          self.map.panBy(-305, -150);
        }
        self.setInfowindow(marker.id);

    };

    self.updateMapPan = function (marker) {
        if (window.innerWidth > 1199) {
            
            if (!self.hasFilters && self.isCentered) {
                self.map.panBy(-305, -150);
                self.isCentered = false;
            }
            
        } else {

                self.isCentered = true;
                EventBus.publish('pan-map-to', {
                    location: marker.position,
                    map: self.map
                    
                });

        }
       
    };

    /**
     * Display nearest distributor to location
     * @param coordinates
     */
    self.showNearestDistributor = function (coordinates) {
        
    //     //Get distances of each distributor from coordinates
        var distances = [];
        jQuery.each(distributorData, function (id, distributor) {
            //Cancel if distributor has no coordinates
            if (!distributor.hasOwnProperty('coordinates') || distributor.coordinates.latitude == "" || distributor.coordinates.longitude == "") {
                return;
            }
            
            //Get distance for individual distributor
            EventBus.publish('get-distance', {
                lat1: distributor.coordinates.latitude,
                lon1: distributor.coordinates.longitude,
                lat2: coordinates.latitude,
                lon2: coordinates.longitude,
                callback: function (distance) {
                    distances.push({
                        value: distance,
                        id: id
                    });
                }
            });
            
            //Sort distances ascending
            distances.sort(function (a, b) {
                return a.value - b.value;
            });
            
        });
        
        //Compile HTML
        
        var
            distributor,
            company,
            address,
            email,
            telephone,
            website,
            type,
            logo
        ;
        

        self.updateMapDistributor(self.markers[distances[0].id]);
        self.nearestMarker = self.markers[distances[0].id];
        
    };

    self.resetMap = function() {
        self.updateMapDistributor(self.nearestMarker, true);
    };

    self.showDistributorOnResize = function(coordinates) {
      self.infoWindow = null;
      self.showNearestDistributor(coordinates);
      self.setInfowindow(self.nearestMarker.id, true);
    };

    self.resizeWindow = function () {

      var resizeId;
        if (window.innerWidth < 1199) {
            clearTimeout(resizeId);
            resizeId = setTimeout(self.showDistributorOnResize(self.coordinates), 500);
        }
        if (window.innerWidth > 1299) {
            clearTimeout(resizeId);
            resizeId = setTimeout(self.resetMap(), 2500);
        }
    }

    /**
     * setup Styled Map Type
     */
    self.setupStyledMapType = function () {
        var styles = [
            { 
                "featureType": "all",
                "elementType": "geometry", 
                "stylers": [ 
                        { "color": "#0c58b6" } 
                ]
            }, 
            { 
                "featureType": "all",
                "elementType": "geometry.fill", 
                "stylers": [ 
                    { "color": "#0c58b6" } 
                ] 
            }, 
            { 
                "featureType": "all",
                "elementType": "geometry.stroke", 
                "stylers": [ 
                    { "color": "#0c58b6" } 
                ] 
            }, 
            { 
                "featureType": "all",
                "elementType": "labels", 
                "stylers": [ 
                    { "color": "#0c58b6" } 
                ] 
            }, 
            {
                "featureType": "all", 
                "elementType": "labels.text.fill", 
                "stylers": [ 
                    { "color": "#ffffff" } 
                ] 
            }, 
            { 
                "featureType": "all",
                "elementType": "labels.text.stroke", "stylers": [ 
                    { "color": "#155bb3" }, 
                    { "lightness": -30 }, 
                    { "weight": 1.5 } 
                ] 
            }, 
            { 
                "featureType": "administrative", 
                "elementType": "geometry", 
                "stylers": [ 
                    { "visibility": "off" } 
                ] 
            }, 
            { 
                "featureType": "administrative.country", 
                "elementType": 
                "geometry.stroke", 
                "stylers": [ 
                    { "color": "#ffffff" } 
                ] 
            }, 
            { "featureType": "administrative.land_parcel", 
                "stylers": [ 
                    { "visibility": "off" } 
                ] 
            }, 
            { 
                "featureType": "administrative.land_parcel", 
                "elementType": "labels.text.fill", "stylers": [ 
                    { "color": "#64779e" } 
                ] 
            }, 
            {
                 "featureType": "administrative.neighborhood", 
                 "stylers": [ 
                    { "visibility": "off" } 
                 ] 
            }, 
            { 
                "featureType": "administrative.province", 
                "elementType": "geometry.stroke", 
                "stylers": [ 
                    { "color": "#ffffff" } 
                ] 
            }, 
            {
                "featureType": "landscape.man_made", 
                "elementType": "geometry.fill", 
                "stylers": [ 
                    { "color": "#719fda" } 
                ] 
            }, 
            { 
                "featureType": "landscape.man_made", 
                "elementType": "geometry.stroke", 
                "stylers": [ 
                    { "color": "#73a0d8" } 
                ] 
            }, 
            { 
                "featureType": "landscape.natural", 
                "elementType": "geometry",
                "stylers": [ 
                    { "color": "#0c58b6" } 
                ] 
            }, 
            {
                "featureType": "poi", 
                "stylers": [ 
                    { "visibility": "off" } 
                ] 
             }, 
             { 
                "featureType": "poi",
                "elementType": "labels.text", 
                "stylers": [ 
                    { "visibility": "off" } 
                ] 
             }, 
             { 
                "featureType": "road",
                "elementType": "geometry", 
                "stylers": [ 
                    { "color": "#73a0d8" }, 
                    { "lightness": -20 } 
                ] 
             }, 
             {
                 "featureType": "road", 
                 "elementType": "labels", 
                 "stylers": [ 
                    { "visibility": "off" } 
                 ] 
             }, 
             { 
                "featureType": "road", 
                "elementType": "labels.icon", 
                "stylers": [ 
                    { "visibility": "off" } 
                ] 
             }, 
             { 
                "featureType": "road", 
                "elementType": "labels.text.fill", 
                "stylers": [ 
                    { "color": "#98a5be" } 
                ] 
             },
             { 
                "featureType": "road", 
                "elementType": "labels.text.stroke", 
                "stylers": [ 
                        { "color": "#1d2c4d" } 
                ] 
            }, 
            { 
                "featureType": "road.highway", 
                "elementType": "geometry", 
                "stylers": [ 
                    { "color": "#73a0d8" }, 
                    { "lightness": 10 } 
                ] 
            }, 
            { 
                "featureType": "road.highway", 
                "elementType": "geometry.stroke", 
                "stylers": [ 
                    { "lightness": -35 }, 
                    { "visibility": "off" } 
                ] 
            }, 
            { 
                "featureType": "transit", 
                "stylers": [ 
                    { "visibility": "off" } 
                ] 
            }, 
            { 
                "featureType": "transit", 
                "elementType": "labels.text.fill", 
                "stylers": [
                    { "color": "#98a5be" } 
                 ] 
            }, 
            { 
                "featureType": "transit",
                "elementType": "labels.text.stroke", 
                "stylers": [ 
                    { "color": "#1d2c4d" } 
                ] 
            }, 
            { 
                "featureType": "transit.line",
                 "elementType": "geometry.fill", 
                 "stylers": [ 
                    { "color": "#ffffff" } ] 
            }, 
            {
                 "featureType": "transit.station",
                 "elementType": "geometry", 
                 "stylers": [ 
                    { "color": "#3a4762" } 
                 ] 
            }, 
            { 
                "featureType": "water", 
                "elementType": "geometry", 
                "stylers": [ 
                    { "color": "#000000" } 
                ] 
            }, 
            { 
                "featureType": "water", 
                "elementType": "labels.text", 
                "stylers": [ 
                    { "visibility": "off" } 
                ] 
            }, 
            { 
                "featureType": "water", 
                "elementType": "labels.text.fill", 
                "stylers": [ 
                    { "color": "#4e6d70" } 
                ] 
            } 
        ];

        var styledMapType = new google.maps.StyledMapType(
            styles,
            { name: 'styled map'}
        );

        self.map.mapTypes.set('styled_map', styledMapType);
        self.map.setMapTypeId('styled_map');
    };

    /**
     * Bind events
     */
    self.bindEvents = function () {
        EventBus.subscribe('run-modules', self.loadGoogleApi);
        EventBus.subscribe('modules-initialised', self.modulesInitialised);
        EventBus.subscribe('distributor-map-distributor-selected', self.distributorSelected);
        EventBus.subscribe('distributor-map-filter-updated', self.distributorsFiltered);
        window.addEventListener('resize', self.resizeWindow);

    };

    self.bindEvents();

};

EventBus.subscribe('init-modules', function () {
    new DistributorMapModule();
});
