var CableBuilderSelect = {
    make: function(name, title, options, addPlaceholder, $parentContainer) {
        $select = $('<select/>', {
            name: name,
            class: 'connector-type'
        })

        if (addPlaceholder) {
            $select.append($('<option/>', {value: '', text: title}))
        }

        for (var i = 0; i < options.length; i++) {
            $select.append($('<option/>', {text: options[i][name]}))
        }

        $container = $('<div/>', {
            class: 'builder-config-option collapse'
        })

        $container.append($select)

        $parentContainer.append($container)

        return $select
    }
}
