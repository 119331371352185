var SelectPowerFunction = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'cable_function'
    this.title = 'Select function'
    this.modelName = 'connector_one'
}

SelectPowerFunction.prototype = Object.create(CableBuilderStep.prototype)

SelectPowerFunction.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        }
    ]
}
