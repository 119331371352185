/*
    Make all child elements matching height according to which is the biggest
*/

(function () {
    
    /**
     * Synchronise child heights of all selectable parents
    */
    var syncHeights = function () {
        jQuery('.sync-child-heights').each(
            function () {
                syncHeight(jQuery(this));
            }
        );
    };
    
    /**
     * Synchronise child heights of specific parent
     * @param parent
    */
    var syncHeight = function (parent) {
        
        var
            target,
            maxHeight = 0,
            minSyncWidth = parent.data('min-sync-width'),
            display,
            targets = parent.data('targets'),
            startsHidden = false
        ;
        
        if (!targets) {
            targets = '> *';
        }
        
        //Show the parent if it starts hidden so child height can be calculated
        if (parent.data('starts-hidden') && parent.css('display') == 'none') {
            startsHidden = true;
            parent.show();
        }
        else {
            startsHidden = false;
        }
        
        jQuery.each(targets.split(','), function (index, value) {
            
            //Reset heights if screen size is below specified breakpoint
            if (minSyncWidth && !window.matchMedia('(min-width: ' + minSyncWidth + 'px)').matches) {
                parent.find(value).height('');
                return;
            }
            
            maxHeight = 0;
            
            parent.find(value).each(
                function () {
                    target = jQuery(this);
                    display = target[0].style.display;
                    target.height('').css('display', 'block');
                    maxHeight = Math.max(maxHeight, target.height());
                    target[0].style.display = display;
                }
            ).height(maxHeight);
            
        });
        
        //Re-hide parent if it starts hidden
        if (startsHidden) {
            parent.hide();
        }
        
    };
    
    jQuery(window).resize(syncHeights);
    jQuery(document).on('sync-child-heights', syncHeights);
    syncHeights();
    
})();