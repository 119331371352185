var EventBus = {
    topics: {},

    subscribe: function (topic, listener) {
        // create the topic if not yet created
        if (!this.topics.hasOwnProperty(topic)) this.topics[topic] = [];

        // add the listener
        this.topics[topic].push(listener);
        
        return this;
    },
    
    unsubscribe: function (topic, listener) {
        
        // return if the topic doesn't exist
        if (!this.topics.hasOwnProperty(topic)) return;
        
        //Remove the listener from the topic
        var index = this.topics[topic].indexOf(listener);
        if (index > -1) {
            this.topics[topic].splice(index, 1);
        }
        
    },

    publish: function (topic, data) {
        // return if the topic doesn't exist, or there are no listeners
        if (!this.topics.hasOwnProperty(topic) || this.topics[topic].length < 1) return;

        // send the event to all listeners
        this.topics[topic].forEach(function (listener) {
            listener(data || {});
        });
        
        return this;
    }
};