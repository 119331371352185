var SetMinMaxDataCableEntrySize = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.modelName = 'connector_one'
}

SetMinMaxDataCableEntrySize.prototype = Object.create(CableBuilderStep.prototype)

SetMinMaxDataCableEntrySize.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'ethernet_category',
            value: customCableData.connector_one.ethernet_category
        },
        {
            field: 'body_material_type',
            value: customCableData.connector_one.body_material_type
        }
    ]
}

SetMinMaxDataCableEntrySize.prototype.getSearchFields = function() {
    return [
        {
            value: 'min_cable_entry_size'
        },
        {
            value: 'max_cable_entry_size'
        }                
    ]
}

SetMinMaxDataCableEntrySize.prototype.renderOptions = function(response) {
    if (response.items.length < 1) {
        return alert('Your search has yielded zero options. Please reset all filters and start again.')
    }

    var minValues = response.items.map(function(x) { 
        return parseFloat(x.min_cable_entry_size)
    })

    var maxValues = response.items.map(function(x) { 
        return parseFloat(x.max_cable_entry_size)
    })

    customCableData[this.modelName].min_cable_entry_size = Math.min.apply(Math, minValues)
    customCableData[this.modelName].max_cable_entry_size = Math.max.apply(Math, maxValues)

    this.run()
}
