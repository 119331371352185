//TODO refactor global data
var customCableData = {
    connector_family: '',
    connector_count: '',
    cable: {},
    connector_one: {},
    connector_two: {},
    connector_three: {},
    additional: {}
}


var CustomCableBuilder = function($el) {
    this.$el = $el
    this.$connectorTypeContainer = this.$el.find('.connector-type-container')
    this.$cableTypeContainer = this.$el.find('.cable-type-container')
    this.$diagramContainer = this.$el.find('.diagram-container')
    this.$tripleDiagram = this.$el.find('.triple-diagram')
    this.$doubleDiagram = this.$el.find('.double-diagram')
    this.$singleDiagram = this.$el.find('.single-diagram')
    this.$connectorOneContainer = this.$el.find('.connector-one-container')
    this.$connectorTwoContainer = this.$el.find('.connector-two-container')
    this.$connectorThreeContainer = this.$el.find('.connector-three-container')
    this.$additionalContainer = this.$el.find('.additional-container')
    this.$cableContainer = this.$el.find('.cable-container')
    this.$pdfButton = this.$el.find('.pdf-button')
    this.$buildNumber = this.$el.find('.build-number')
    this.$enquireButton = this.$el.find('.enquire-button')
    this.lang = this.$el.data('lang')
    this.domain = this.$el.data('domain')
    this.search = new CableBuilderSearchClient('/' + this.lang + '/products')

    this.bindEvents()
}

CustomCableBuilder.prototype.bindEvents = function() {
    this.$connectorTypeContainer.on('click', 'button.product-link', this.setConnectorType.bind(this))
    this.$cableTypeContainer.on('click', 'button.product-link', this.setCableType.bind(this))
    this.$connectorOneContainer.on('connector-selected', this.updateDiagram.bind(this))
    this.$connectorTwoContainer.on('connector-selected', this.updateDiagram.bind(this))
    this.$connectorThreeContainer.on('connector-selected', this.updateDiagram.bind(this))
    this.$cableContainer.on('cable-selected', this.updateDiagram.bind(this))
    this.$el.on('steps-finished', this.emitCableBuilt.bind(this))
    this.$el.on('cable-built', this.showPdfButton.bind(this))
    this.$el.on('cable-built', this.showEnquireButton.bind(this))
}

CustomCableBuilder.prototype.emitCableBuilt = function() {
    this.$el.trigger('cable-built', {
        title: this.getTitle(),
        pdf_link: this.getPdfLink(),
        cable_icon: this.getCableIconPath(),
        summary: this.getSummary(),
        selected_data: customCableData
    })
}

CustomCableBuilder.prototype.getDiagram = function() {
    if (this.isTriple()) {
        return this.$tripleDiagram
    } else if (this.isDouble()) {
        return this.$doubleDiagram
    } 
    
    return this.$singleDiagram
}

CustomCableBuilder.prototype.getPdfLink = function() {
    var url = this.domain + "/" + this.lang + "/products/customcablebuilder/pdf/index"
    url += "?qty=" + customCableData.additional.qty 
    url += "&length=" + customCableData.cable.length 
    url += "&cable=" + customCableData.cable.sku 
    url += "&connector1=" + customCableData.connector_one.sku 

    if (typeof(customCableData.connector_two.sku) !== 'undefined') {
        url += "&connector2=" + customCableData.connector_two.sku 
    }

    if (typeof(customCableData.connector_three.sku) !== 'undefined') {
        url += "&connector3=" + customCableData.connector_three.sku 
    }

    return url
}

CustomCableBuilder.prototype.getSummary = function() {
    var summary = [];

    if (typeof(customCableData.connector_one.number_of_contacts) !== 'undefined') {
        summary.push("Number Of Contacts: " + customCableData.connector_one.number_of_contacts)
    }

    summary.push("Cable Sheath Colour: " + customCableData.cable.sheath_colour)
    summary.push("Cable Sheath Material: " + customCableData.cable.sheath_material)
    summary.push("Cable Length: " + customCableData.cable.length)
    summary.push("Cable Diameter: " + customCableData.cable.diameter)

    return summary.join('<span>|</span>');
}

CustomCableBuilder.prototype.getTitle = function() {
    if (this.isTriple()) {
        return  "Triple " + customCableData.connector_family + " // Twin Cable";
    } 

    if (this.isDouble()) {
        return "Double " + customCableData.connector_family + " // Single Cable";
    } 

    return "Single " + customCableData.connector_family + " // Single Cable";
}

CustomCableBuilder.prototype.isDouble = function() {
    return customCableData.connector_count == 2
}

CustomCableBuilder.prototype.isGreaterThanSingle = function() {
    return customCableData.connector_count > 1
}

CustomCableBuilder.prototype.isTriple = function() {
    return customCableData.connector_count == 3
}

CustomCableBuilder.prototype.setConnectorType = function(event) {
    customCableData.connector_family = event.currentTarget.value
    this.$connectorTypeContainer.hide()
    this.$cableTypeContainer.fadeIn()
}

CustomCableBuilder.prototype.setCableType = function(event) {
    customCableData.connector_count = event.currentTarget.value
    this.$cableTypeContainer.hide()
    this.showDiagram()
    this.$diagramContainer.fadeIn()
    this.runSteps()
}

CustomCableBuilder.prototype.runSteps = function() {
    switch(customCableData.connector_family) {
        case 'Circular Power Connectors':
            this.runPowerSteps()
            break;
        case 'Circular Data Connectors':
            this.runDataSteps()
            break;
        default:
            this.runFibreSteps()
    }  
}

CustomCableBuilder.prototype.getCableIconPath = function() {
    if (this.isTriple()) {
        return '/wp-content/themes/bulgin/assets/img/icons/twin-cable-connector.jpg';
    } 

    if (this.isDouble()) {
        return '/wp-content/themes/bulgin/assets/img/icons/double-connector.jpg';
    } 

    return '/wp-content/themes/bulgin/assets/img/icons/single-connector.jpg';
}

CustomCableBuilder.prototype.showDiagram = function() {
    this.getDiagram()
        .fadeIn()
}

CustomCableBuilder.prototype.showPdfButton = function(event, cable) {
    this.$pdfButton
        .attr('href', cable.pdf_link)
        .fadeIn()
}

CustomCableBuilder.prototype.updateDiagram = function() {
    var $cableBox = this.getDiagram().find('.connector-box').eq(1)
    var $connectorOneBox = this.getDiagram().find('.connector-box').eq(0)
    var $connectorTwoBox = this.getDiagram().find('.connector-box').eq(2)
    var $connectorThreeBox = this.getDiagram().find('.connector-box').eq(3)
    var cableImageKey = $connectorThreeBox.length > 0 ? 'y_cable_configurator_image' : 'straight_cable_configurator_image'
    var cableClass = $connectorThreeBox.length > 0 ? 'double-stack' : ''

    if (typeof(customCableData.cable[cableImageKey]) !== 'undefined') {
        $cableBox.addClass('right-align-img img ' + cableClass)

        $cableBox.html($('<span/>', {
            style: "background-image: url(/" + this.lang + "/products/pub/media/catalog/product" + customCableData.cable[cableImageKey] + ");"
        }))
    }

    if (typeof(customCableData.connector_one.product_configurator_image) !== 'undefined') {
        $connectorOneBox.addClass('right-align-img img')

        $connectorOneBox.html($('<span/>', {
            style: "background-image: url(/" + this.lang + "/products/pub/media/catalog/product" + customCableData.connector_one.product_configurator_image + ");"
        }))
    }

    if ($connectorTwoBox.length > 0 && typeof(customCableData.connector_two.product_configurator_image) !== 'undefined') {
        $connectorTwoBox.addClass('right-align-img img flipped ')

        $connectorTwoBox.html($('<span/>', {
            style: "background-image: url(/" + this.lang + "/products/pub/media/catalog/product" + customCableData.connector_two.product_configurator_image + ");"
        }))
    }

    if ($connectorThreeBox.length > 0 && typeof(customCableData.connector_three.product_configurator_image) !== 'undefined') {
        $connectorThreeBox.addClass('right-align-img img flipped')

        $connectorThreeBox.html($('<span/>', {
            style: "background-image: url(/" + this.lang + "/products/pub/media/catalog/product" + customCableData.connector_three.product_configurator_image + ");"
        }))
    }
}

CustomCableBuilder.prototype.showEnquireButton = function() {
    this.$enquireButton
        .fadeIn()
}

CustomCableBuilder.prototype.runPowerSteps = function(event) {
    if (this.isTriple()) {
        var step23 = new InputQty(this.$additionalContainer, new CableBuilderFinish(this.$el))
        var step22 = new SelectAddtionalPowerConnector(this.$connectorThreeContainer, this.search, step23, 'connector_three')
        var step21 = new SelectAddtionalPowerBodyMaterialType(this.$connectorThreeContainer, this.search, step22, 'connector_three')
        var step20 = new SelectAddtionalPowerContactType(this.$connectorThreeContainer, this.search, step21, 'connector_three')
        var step19 = new SelectAddtionalPowerFunction(this.$connectorThreeContainer, this.search, step20, 'connector_three')
    } else { 
        var step19 = new InputQty(this.$additionalContainer, new CableBuilderFinish(this.$el))
    }

    if (this.isGreaterThanSingle()) {
        var step18 = new SelectAddtionalPowerConnector(this.$connectorTwoContainer, this.search, step19, 'connector_two')
        var step17 = new SelectAddtionalPowerBodyMaterialType(this.$connectorTwoContainer, this.search, step18, 'connector_two')
        var step16 = new SelectAddtionalPowerContactType(this.$connectorTwoContainer, this.search, step17, 'connector_two')
        var step15 = new SelectAddtionalPowerFunction(this.$connectorTwoContainer, this.search, step16, 'connector_two')
    } else {
        var step15 = new InputQty(this.$additionalContainer, new CableBuilderFinish(this.$el))
    }

    var step14 = new SelectPowerConnector(this.$connectorOneContainer, this.search, step15, 'connector_one')
    var step13 = new InputCableLength(this.$cableContainer, step14)
    var step12 = new SelectPowerCable(this.$cableContainer, this.search, step13)
    var step11 = new SelectPowerCableSheathColour(this.$cableContainer, this.search, step12)
    var step10 = new SelectPowerCableSheathMaterial(this.$cableContainer, this.search, step11)
    var step9 = new SelectPowerCableVoltageMax(this.$cableContainer, this.search, step10)
    var step8 = new SelectPowerCableCurrentMax(this.$cableContainer, this.search, step9)
    var step7 = new SetMinMaxPowerContactAccomodation(this.$connectorOneContainer, this.search, step8)
    var step6 = new SelectPowerBodyMaterialType(this.$connectorOneContainer, this.search, step7)
    var step5 = new SelectPowerVoltageMax(this.$connectorOneContainer, this.search, step6)
    var step4 = new SelectPowerCurrentMax(this.$connectorOneContainer, this.search, step5)
    var step3 = new SelectPowerNumberOfContacts(this.$connectorOneContainer, this.search, step4)
    var step2 = new SelectPowerContactType(this.$connectorOneContainer, this.search, step3)
    var step1 = new SelectPowerFunction(this.$connectorOneContainer, this.search, step2)
    var start = new CableBuilderStart(this.$connectorOneContainer, this.search, step1)

    start.run()
}

CustomCableBuilder.prototype.runDataSteps = function() {
    if (this.isTriple()) {
        var step14 = new InputQty(this.$additionalContainer, new CableBuilderFinish(this.$el))
        var step13 = new SelectDataConnector(this.$connectorThreeContainer, this.search, step14, 'connector_three')
        var step12 = new SelectAddtionalDataBodyMaterialType(this.$connectorThreeContainer, this.search, step13, 'connector_three')
    } else {
        var step12 = new InputQty(this.$additionalContainer, new CableBuilderFinish(this.$el))
    }

    if (this.isGreaterThanSingle()) {
        var step11 = new SelectDataConnector(this.$connectorTwoContainer, this.search, step12, 'connector_two')
        var step10 = new SelectAddtionalDataBodyMaterialType(this.$connectorTwoContainer, this.search, step11, 'connector_two')
    } else {
        var step10 = new InputQty(this.$additionalContainer, new CableBuilderFinish(this.$el))
    }

    var step9 = new SelectDataConnector(this.$connectorOneContainer, this.search, step10, 'connector_one')
    var step8 = new InputCableLength(this.$cableContainer, step9)
    var step7 = new SelectDataCable(this.$cableContainer, this.search, step8)
    var step6 = new SelectDataCableSheathColour(this.$cableContainer, this.search, step7)
    var step5 = new SelectDataCableSheathMaterial(this.$cableContainer, this.search, step6)
    var step4 = new SelectDataCableDiameter(this.$cableContainer, this.search, step5)
    var step3 = new SetMinMaxDataCableEntrySize(this.$cableContainer, this.search, step4)
    var step2 = new SelectDataBodyMaterialType(this.$connectorOneContainer, this.search, step3)
    var step1 = new SelectDataEthernetCategory(this.$connectorOneContainer, this.search, step2)
    var start = new CableBuilderStart(this.$connectorOneContainer, this.search, step1)

    start.run()
}

CustomCableBuilder.prototype.runFibreSteps = function() {
    if (this.isTriple()) {
        var step15 = new InputQty(this.$additionalContainer, new CableBuilderFinish(this.$el))
        var step14 = new SelectFibreConnector(this.$connectorThreeContainer, this.search, step15, 'connector_three')
        var step13 = new SelectAddtionalFibreProductFunction(this.$connectorThreeContainer, this.search, step14, 'connector_three')
        var step12 = new SelectAddtionalFibreProductSeries(this.$connectorThreeContainer, this.search, step13, 'connector_three')
    } else {
        var step12 = new InputQty(this.$additionalContainer, new CableBuilderFinish(this.$el))
    }

    if (this.isGreaterThanSingle()) {
        var step11 = new SelectFibreConnector(this.$connectorTwoContainer, this.search, step12, 'connector_two')
        var step10 = new SelectAddtionalFibreProductFunction(this.$connectorTwoContainer, this.search, step11, 'connector_two')
        var step9 = new SelectAddtionalFibreProductSeries(this.$connectorTwoContainer, this.search, step10, 'connector_two')
    } else {
        var step9 = new InputQty(this.$additionalContainer, new CableBuilderFinish(this.$el))
    }

    var step8 = new SelectFibreConnector(this.$connectorOneContainer, this.search, step9, 'connector_one')
    var step7 = new InputCableLength(this.$cableContainer, step8)
    var step6 = new SelectFibreCable(this.$cableContainer, this.search, step7)
    var step5 = new SelectFibreCableSheathColour(this.$cableContainer, this.search, step6)
    var step4 = new SelectFibreCableSheathMaterial(this.$cableContainer, this.search, step5)
    var step3 = new SelectFibreMode(this.$connectorOneContainer, this.search, step4)
    var step2 = new SelectFibreProductFunction(this.$connectorOneContainer, this.search, step3)
    var step1 = new SelectFibreProductSeries(this.$connectorOneContainer, this.search, step2)
    var start = new CableBuilderStart(this.$connectorOneContainer, this.search, step1)

    start.run()
}

$('.custom-cable-builder').each(function () {
    new CustomCableBuilder($(this))
})
