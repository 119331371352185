var SelectDataCableSheathColour = function($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'sheath_colour'
    this.title = 'Select sheath colour'
    this.modelName = 'cable'
}

SelectDataCableSheathColour.prototype = Object.create(CableBuilderStep.prototype)

SelectDataCableSheathColour.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: 'Cables'
        },
        {
            field: 'ethernet_category',
            value: customCableData.connector_one.ethernet_category
        },
        {
            field: 'diameter',
            value: customCableData.cable.diameter
        },               
        {
            field: 'sheath_material',
            value: customCableData.cable.sheath_material
        }                
    ]
}
