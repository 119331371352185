var CableBuilderSearchClient = function(baseUrl) {
    this.baseUrl = baseUrl
    this.endpoint = '/rest/V1/custom-cable-builder/search'
}

CableBuilderSearchClient.prototype.get = function(filters, fields, done, fail, $container) {
    var data = {
        search_criteria: {
            filters: filters,
            fields: fields
        }
    }

    $container
        .append($('.loader-container').html())
        .parent()
        .fadeIn()

    $.get(this.url(), data)
        .done(done)
        .fail(fail)
        .always(function() {
            $container
                .find('.loader')
                .first()
                .remove()
        })
}

CableBuilderSearchClient.prototype.url = function() {
    return this.baseUrl + this.endpoint
}
