var filterDistributorsMapModule = function () {
    
    var self = this;

    self.distributorList = jQuery('.distributor-list-items');

    if (self.distributorList.length ===0) {
        return;
    }

    
    /**
     * A distributor filter has been updated
     * */
    self.filterUpdated = function () {
        //update map with first distributor in list
        EventBus.publish('distributor-map-filter-updated', 
            self.distributorList.children().first().find('.show-on-map').data('distributor-id')
        );
    };
    /**
     * Bind events
     */
    self.bindEvents = function () {
        jQuery(document).on('click', '#distributors-filters-btn', self.filterUpdated);
    };
    
    self.bindEvents();
    
};

EventBus.subscribe('init-modules', function () {
    new filterDistributorsMapModule();
});