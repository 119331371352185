var SelectPowerNumberOfContacts = function ($container, search, next) {
    CableBuilderStep.call(this, $container, search, next)

    this.optionName = 'number_of_contacts'
    this.title = 'Select number of contacts'
    this.modelName = 'connector_one'
}

SelectPowerNumberOfContacts.prototype = Object.create(CableBuilderStep.prototype)

SelectPowerNumberOfContacts.prototype.getSearchFilters = function() {
    return [
        {
            field: 'product_family',
            value: customCableData.connector_family
        },
        {
            field: 'cable_function',
            value: customCableData[this.modelName].cable_function
        },
        {
            field: 'contact_type',
            value: customCableData[this.modelName].contact_type
        }
    ]
}
